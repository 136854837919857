import type { VariationItems } from 'types/builder/interface';
import type { FC } from 'types/react';

import { setDescriptionEditorStateEvent, $descriptionEditorState } from 'stores/builder/descriptionEditor/model';
import { hideOrShowEvents, hideOrShowStores } from 'stores/builder/hideOrShowItems';
import { RichTextEditor } from 'components/builder/RichTextEditor/RichTextEditor';
import { addBlockVariationsEvent } from 'stores/builder/block/model';
import { variationsBlockModalStore } from 'stores/modals/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { FieldTypesEnum } from 'constants/builder/enums';
import { stateFromHTML } from 'draft-js-import-html';
import { useEffect, useState, useMemo } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { Modal } from 'ui-kit/components/Modal';
import { useUnit } from 'effector-react';
import { v4 as generateId } from 'uuid';
import { EditorState } from 'draft-js';

import { getVariationItems } from '../VariationsFieldModal/helpers/get-variation-items';
import { VariationsEditor } from './VariationsEditor/VariationsEditor';
import { Edit } from './tabs/Edit/Edit';
import { $question } from './model';
import { Footer } from './styles';

export const VariationsBlockModal: FC = () => {
    const { closeModal, modal } = variationsBlockModalStore;
    const [[_, { variationsBlock, blockTitle, blockId }], descriptionEditorState, question, showOrHide] = useUnit([
        modal,
        $descriptionEditorState,
        $question,
        hideOrShowStores.showOrHide
    ]);

    const [activeTab, setActiveTab] = useState('edit');
    const [variationItems, setVariationItems] = useState<VariationItems[]>(() => getVariationItems(variationsBlock));
    const [selectedValue, setSelectedValue] = useState<FieldTypesEnum>(
        variationsBlock ? variationsBlock[0][0].type : FieldTypesEnum.Radio
    );

    const canGoToLogicTab = useMemo(
        () => question && variationItems.every(item => item.text),
        [question, variationItems]
    );

    const onTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    const handleSelectChange = (value: string) => {
        if ((value === FieldTypesEnum.Text || value === FieldTypesEnum.Date) && activeTab === 'logic') {
            setActiveTab('edit');
        }
        setSelectedValue(value as FieldTypesEnum);
    };

    const handleCloseModal = () => {
        const { resetShowOrHide } = hideOrShowEvents;

        resetShowOrHide();
        closeModal();
    };

    const handleSave = () => {
        const descriptionHtml = stateToHTML(descriptionEditorState.getCurrentContent());
        const plainText = descriptionEditorState.getCurrentContent().getPlainText();
        const description = plainText ? descriptionHtml : '';

        const generateRadios = variationItems.map((item, index) => ({
            value: {
                value: showOrHide[index]?.value,
                type: FieldTypesEnum.Text
            },
            isDefault: false,
            text: item.text
        }));

        if (blockId) {
            addBlockVariationsEvent({
                data: [
                    [
                        {
                            value: generateRadios,
                            type: selectedValue,
                            title: question,
                            description,
                            name: '',
                            id: ''
                        }
                    ]
                ],
                blockTitle: blockTitle || '',
                blockId
            });
        }

        handleCloseModal();
    };

    const onEditorStateChange = (editorState: EditorState) => {
        setDescriptionEditorStateEvent(editorState);
    };

    const handleOnChangeAnswers = ({ type = 'add', index }: { index: number; type: string }) => {
        if (type === 'remove') {
            return setVariationItems(prevState => {
                const copyOfState = [...prevState];
                copyOfState.splice(index, 1);
                return [...copyOfState];
            });
        }

        return setVariationItems(prevState => {
            const copyOfState = [...prevState];
            copyOfState.splice(index + 1, 0, {
                value: {
                    value: '',
                    type: ''
                },
                id: generateId(),
                isDefault: true,
                text: ''
            });

            return [...copyOfState];
        });
    };

    useEffect(() => {
        const contentState = stateFromHTML(variationsBlock ? (variationsBlock[0][0].description ?? '') : '');

        setDescriptionEditorStateEvent(EditorState.createWithContent(contentState));
    }, [variationsBlock]);

    return (
        <Modal
            customFooter={
                <Footer>
                    {activeTab === 'logic' ? (
                        <>
                            <Button
                                onClick={() => {
                                    onTabChange('edit');
                                }}
                                variant="secondary"
                            >
                                Back
                            </Button>
                            <Button onClick={handleSave} variant="primary">
                                Save
                            </Button>
                        </>
                    ) : (
                        <Button
                            onClick={() => {
                                onTabChange('logic');
                            }}
                            disabled={!canGoToLogicTab}
                            variant="primary"
                        >
                            Next
                        </Button>
                    )}
                </Footer>
            }
            onClose={handleCloseModal}
            title="Logic"
            width="720px"
            visible
        >
            <Edit
                variationTitle={variationsBlock ? variationsBlock[0][0].title : ''}
                isDisabledEditInput={activeTab === 'logic'}
                handleSelectChange={handleSelectChange}
                selectedValue={selectedValue}
            />
            <VariationsEditor
                handleOnChangeAnswers={handleOnChangeAnswers}
                isDisabledEditInput={activeTab === 'logic'}
                isShownLogicTab={activeTab === 'logic'}
                setVariationItems={setVariationItems}
                variationItems={variationItems}
                selectedValue={selectedValue}
                blockId={blockId}
            />
            <RichTextEditor
                placeholder="Enter an optional explanation..."
                onEditorStateChange={onEditorStateChange}
                editorState={descriptionEditorState}
                canAddField={false}
                forDescription
            />
        </Modal>
    );
};
