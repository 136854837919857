import { HexColorPicker } from 'react-colorful';
import { type FC } from 'types/react';

import './styles.scss';

export interface ColorPickerProps {
    onChange: (color: string) => void;
    defaultColor?: string;
}

export const ColorPicker: FC<ColorPickerProps> = ({ defaultColor = '#000000', onChange }) => (
    <HexColorPicker color={defaultColor} onChange={onChange} />
);
