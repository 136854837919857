import axios from 'api/axios';

export const createDocument = (data: KIARA_API.DocumentRequest) =>
    axios({
        url: 'documents',
        method: 'POST',
        data
    });

export const getDocuments = () =>
    axios<KIARA_API.DocumentResponse[]>({
        url: 'documents',
        method: 'GET'
    });

export const getSingleDocument = (id: string) =>
    axios({
        url: `documents/${id}`,
        method: 'GET'
    });

export const deleteDocument = (id: string) =>
    axios({
        url: `documents/${id}`,
        method: 'DELETE'
    });
//update documents API
export const updateDocument = (data: KIARA_API.DocumentRequest, id: string) =>
    axios<API.ErrorResponse>({
        url: `documents/update/${id}`,
        method: 'PATCH',
        data
    });

// Request Access to Share Data
export const shareDocument = (data: KIARA_API.RequestAccessResponseDto, id: string) =>
    axios<API.ErrorResponse>({
        url: `documents/request_access/${id}`,
        method: 'POST',
        data
    });
