// import { createEvent, createStore, restore } from 'effector';

// import type { Pages } from './helpers/generate-template-pages';

// // * events
// export const setAllBlocksEvent = createEvent<HTMLElement[]>();
// export const setMountingEvent = createEvent<boolean>();
// export const setIsLoadingTemplatePagesEvent = createEvent<boolean>();
// export const addElementIntoPageEvent = createEvent<HTMLElement>();
// export const resetAllBlocksEvent = createEvent();
// export const resetPagesEvent = createEvent();
// export const resetPagesIdsEvent = createEvent();
// export const resetMountingEvent = createEvent();
// export const setActivePageEvent = createEvent<number>();

// // * stores
// export const $allBlocks = restore<HTMLElement[]>(setAllBlocksEvent, []).reset(resetAllBlocksEvent);
// // export const $pages = createStore<Pages>({
// //     1: []
// // })
// //     .on(addElementIntoPageEvent, (pages, element) => {
// //         Object.keys(pages).forEach(key => {
// //             pages[Number(key)].push(element);
// //         });

// //         return pages;
// //     })
// //     .reset(resetPagesEvent);

// export const $pagesIds = createStore<string[]>(['']).reset(resetPagesIdsEvent);
// export const $mounting = restore(setMountingEvent, false).reset(resetMountingEvent);
// export const $isLoadingTemplatePages = restore(setIsLoadingTemplatePagesEvent, false);
// export const $activePage = restore<number>(setActivePageEvent, 1);

// stores/templatePages/model.ts

import { createEvent, createStore, restore } from 'effector';
import type { Pages } from './helpers/generate-template-pages';

// * events
export const setAllBlocksEvent = createEvent<HTMLElement[]>();
export const setMountingEvent = createEvent<boolean>();
export const setIsLoadingTemplatePagesEvent = createEvent<boolean>();
export const addElementIntoPageEvent = createEvent<HTMLElement>();
export const resetAllBlocksEvent = createEvent();
export const resetPagesEvent = createEvent();
export const resetPagesIdsEvent = createEvent();
export const resetMountingEvent = createEvent();
export const setActivePageEvent = createEvent<number>();

export const setPagesEvent = createEvent<Pages>(); // New event to set pages

// * stores
export const $allBlocks = restore<HTMLElement[]>(setAllBlocksEvent, []).reset(resetAllBlocksEvent);

export const $pages = createStore<Pages>({})
    .on(setPagesEvent, (_, pages) => pages) // Update pages immutably
    .reset(resetPagesEvent);

export const $pagesIds = createStore<string[]>(['']).reset(resetPagesIdsEvent);
export const $mounting = restore(setMountingEvent, false).reset(resetMountingEvent);
export const $isLoadingTemplatePages = restore(setIsLoadingTemplatePagesEvent, false);
export const $activePage = restore<number>(setActivePageEvent, 1);
