import type { FC } from 'types/react';

import { ExternalLink } from 'ui-kit/components/links/ExternalLink';
import { Button } from 'ui-kit/components/buttons/Button';
import { $currentLanguageCode } from 'stores/language';
import { P } from 'ui-kit/components/typography/P';
import { Modal } from 'ui-kit/components/Modal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HOME_LINK } from 'constants/routes';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import { Footer } from './styles';

export const CommonErrorModal: FC = () => {
    const currentLanguageCode = useUnit($currentLanguageCode);

    const navigate = useNavigate();
    const { i18n: translator, t } = useTranslation();

    useEffect(() => {
        translator.changeLanguage(currentLanguageCode || 'du');
    }, [currentLanguageCode, translator]);

    const onRefresh = () => {
        window.location.reload();
    };

    const onGoHome = () => {
        navigate(HOME_LINK);
        window.location.reload();
    };

    return (
        <Modal
            customFooter={
                <Footer>
                    <Button variant="secondary" onClick={onRefresh}>
                        {t('commonErrorDialog.refreshButton')}
                    </Button>
                    <Button onClick={onGoHome} variant="primary">
                        {t('commonErrorDialog.returnToHomePageButton')}
                    </Button>
                </Footer>
            }
            title={t('commonErrorDialog.title')}
            visible
        >
            <P>{t('commonErrorDialog.errorText')}</P>
            <P>
                {t('commonErrorDialog.suggestionText')}{' '}
                <ExternalLink href="mailto:support@kiaradocs.com." fontSize="16px" target="_self">
                    support@kiaradocs.com.
                </ExternalLink>
            </P>
        </Modal>
    );
};
