import { createNotifyingSuccessEffect, createNotifyingErrorEffect } from 'utils/store';
import { createEvent, createStore } from 'effector';
import { API } from 'api';

// * events
export const resetOwnTemplatesEvent = createEvent();
export const resetOwnTemplateEvent = createEvent();

// * effects
export const getOwnTemplatesFx = createNotifyingErrorEffect({
    handler: async () => await API.ownTemplates.getTemplates()
});

export const createOwnTemplateFx = createNotifyingSuccessEffect({
    handler: async (data: KIARA_API.TemplateRequest) => await API.ownTemplates.createTemplate(data)
});

export const updateOwnTemplateFx = createNotifyingSuccessEffect({
    handler: async ({ data, id }: { data: KIARA_API.TemplateRequest; id: string }) =>
        await API.ownTemplates.updateTemplate(id, data)
});

export const deleteOwnTemplateFx = createNotifyingSuccessEffect({
    handler: async (id: string) => await API.ownTemplates.deleteTemplate(id)
});

export const getOwnTemplateFx = createNotifyingErrorEffect({
    handler: async (id: string) => await API.ownTemplates.getTemplate(id)
});

// * stores
export const $ownTemplates = createStore<KIARA_API.TemplateResponse[]>([])
    .on(getOwnTemplatesFx.doneData, (_, payload) => payload)
    .reset(resetOwnTemplatesEvent);

export const $ownTemplate = createStore<KIARA_API.TemplateResponse | null>(null)
    .on(getOwnTemplateFx.doneData, (_, payload) => payload)
    .reset(resetOwnTemplateEvent);
