import { deleteDraftFx, updateDraftFx, $draft } from 'stores/drafts/model';
import { questionnaireStores } from 'stores/builder/questionnaire';
import { TEMPLATES_LINK, DRAFTS_LINK } from 'constants/routes';
import { leaveDraftModalStore } from 'stores/modals/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { $coverStyles } from 'stores/builder/design';
import { $blocks } from 'stores/builder/block/model';
import { Modal } from 'ui-kit/components/Modal';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { type FC } from 'types/react';

import { Buttons, Text } from './styles';

export const LeaveDraftModal: FC = () => {
    const { closeModal } = leaveDraftModalStore;
    const navigate = useNavigate();
    const [draft, blocks, { questions }, coreConfig] = useUnit([
        $draft,
        $blocks,
        questionnaireStores.questionnaireItems,
        $coverStyles
    ]);

    const deleteDraft = () => {
        if (draft) {
            const { id } = draft;
            deleteDraftFx(id);
            navigate(TEMPLATES_LINK);
            closeModal();
        }
    };

    const saveDraft = () => {
        if (draft) {
            const {
                draft: { templateCategory, documentName, templateName, templateType },
                id
            } = draft;

            updateDraftFx({
                templateConfig: {
                    detailsConfig: blocks.blocks,
                    questionnaire: questions,
                    coreConfig
                },
                templateCategory,
                documentName,
                templateName,
                templateType,
                id
            });
            navigate(DRAFTS_LINK);
            closeModal();
        }
    };

    return (
        <Modal onClose={closeModal} title="Leave Draft" visible>
            <Text>Do you want to save the Draft?</Text>
            <Buttons>
                <Button onClick={deleteDraft} variant="secondary">
                    Delete
                </Button>
                <Button onClick={saveDraft} variant="primary">
                    Save
                </Button>
            </Buttons>
        </Modal>
    );
};
