import { questionnaireEvents } from 'stores/builder/questionnaire';
import { resetBlocksEvent } from 'stores/builder/block/model';
import { templateModalStore } from 'stores/modals/model';
import { TEMPLATES_LINK } from 'constants/routes';
import { browserHistory } from 'browserHistory';

import {
    resetOwnTemplateEvent,
    createOwnTemplateFx,
    updateOwnTemplateFx,
    deleteOwnTemplateFx,
    getOwnTemplatesFx
} from './model';

updateOwnTemplateFx.doneData.watch(() => {
    browserHistory.push(TEMPLATES_LINK);

    resetBlocksEvent();
    questionnaireEvents.resetQuestionnaireEvent();
    resetOwnTemplateEvent();
});

createOwnTemplateFx.doneData.watch(() => {
    templateModalStore.closeModal();
    resetBlocksEvent();
    browserHistory.push(TEMPLATES_LINK);
});

updateOwnTemplateFx.doneData.watch(() => {
    templateModalStore.closeModal();
    browserHistory.push(TEMPLATES_LINK);
    resetOwnTemplateEvent();
    resetBlocksEvent();
    questionnaireEvents.resetQuestionnaireEvent();
});

deleteOwnTemplateFx.doneData.watch(() => {
    getOwnTemplatesFx(null);
    resetBlocksEvent();
});
