import { PURPLE_100, GRAY_100, GRAY_40 } from 'ui-kit/GlobalStyle/colors';
import { P } from 'ui-kit/components/typography/P';
import { ABOVE } from 'ui-kit/GlobalStyle/zIndex';
import styled from 'styled-components';
import media from 'utils/media-query';

export const CookieConsentWrapper = styled.div`
    position: fixed;
    background: ${PURPLE_100};
    color: ${GRAY_40};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 22px;
    padding-bottom: 22px;
    z-index: ${ABOVE * 5};
    bottom: 0;
    gap: 20px;
`;

export const TextContainer = styled(P)`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    max-width: 596px;

    ${media.tablet`
        max-width: 470px;
    `}

    ${media.phone`
        max-width: 100%;
        margin-bottom: 32px;
    `}
`;

export const HighlightedText = styled.span`
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.3s;

    &:hover {
        color: ${GRAY_100};
    }
`;
