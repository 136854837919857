import { refreshTokenFx } from '../model';

export const checkIsAuthorized = async (): Promise<{ isAuthorized: boolean; accessToken?: string }> => {
    try {
        const { accessToken } = await refreshTokenFx(null);

        return {
            isAuthorized: true,
            accessToken
        };
    } catch (error) {
        return {
            isAuthorized: false
        };
    }
};
