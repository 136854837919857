import type { FC } from 'types/react';

import { upgradeProfessionalConfirmationStore } from 'stores/modals/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { Modal } from 'ui-kit/components/Modal';
import { useUnit } from 'effector-react';

import {
    ButtonsContainer,
    LeftContainer,
    BodyContainer,
    BillingCycle,
    CloseButton,
    Month,
    Price,
    Title,
    Text
} from './styles';

export const UpgradeProfessionalConfirmationModal: FC = () => {
    const { closeModal, modal } = upgradeProfessionalConfirmationStore;
    const [_, { handleUpgrade, billingCycle, isMonth, title, price, text }] = useUnit(modal);

    const upgradeSubscription = () => {
        handleUpgrade();
        closeModal();
    };

    return (
        <Modal visible>
            <CloseButton
                onClick={() => {
                    closeModal();
                }}
            >
                X
            </CloseButton>
            <BodyContainer>
                <Title>{title}</Title>
                <Text>{text}</Text>
                <Month>{isMonth}</Month>
                <ButtonsContainer>
                    <LeftContainer>
                        <Price>{price}</Price>
                        <BillingCycle>{billingCycle}</BillingCycle>
                    </LeftContainer>

                    <Button
                        onClick={() => {
                            upgradeSubscription();
                            closeModal();
                        }}
                        variant="primary"
                    >
                        Upgrade
                    </Button>
                </ButtonsContainer>
            </BodyContainer>
        </Modal>
    );
};
