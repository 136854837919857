import * as kiaraTemplates from 'api/services/kiaraTemplates';
import * as copyTemplates from 'api/services/copyTemplates';
import * as ownTemplates from 'api/services/ownTemplates';
import * as contracts from 'api/services/contracts';
import * as templates from 'api/services/templates';
import * as documents from 'api/services/documents';
import * as contact from 'api/services/contact';
import * as company from 'api/services/company';
import * as mollie from 'api/services/mollie';
import * as draft from 'api/services/draft';
import * as cover from 'api/services/cover';
import * as role from 'api/services/role';
import * as user from 'api/services/user';
import * as copyDrafts from 'api/services/copyDrafts';

export const API = {
    kiaraTemplates,
    copyTemplates,
    ownTemplates,
    copyDrafts,
    contracts,
    templates,
    documents,
    contact,
    company,
    mollie,
    draft,
    cover,
    user,
    role
};
