import { DescriptionBadge } from 'components/common/DescriptionBadge';
import { InputTitle } from 'ui-kit/components/typography/Span';
import { useEffect, useState, useMemo } from 'react';
import { type FC } from 'types/react';
import { useToggle } from 'react-use';

import { ShowPasswordIconContainer, StyledInput, Container, Content } from './styles';
import { EyeIconClose, EyeIconOpen } from './static_assets';

// Define the props interfaces
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    reference?: React.RefObject<HTMLInputElement> | null;
    refik?: React.RefObject<any>;
    isPreviewMode?: boolean; // New prop for preview mode
    isFormInput?: boolean;
    description?: string;
    isEllipsis?: boolean;
    titleColor?: string;
    isTouched?: boolean;
    value?: string;
    error?: string;
}

export interface InputDecorationProps {
    type?: InputProps['type'];
    untouchedWarning?: string;
    defaultValue?: string;
    description?: string;
    placeholder?: string;
    subtitle?: string;
    title?: string;
}

export interface StyledTextInputProps
    extends Omit<InputProps, 'defaultChecked' | 'defaultValue'>,
        InputDecorationProps {
    autoComplete?: string;
    titleColor?: string;
    disabled?: boolean;
    touched?: boolean;
    error?: string;
    value?: string;
}

// Define the Input component
export const Input: FC<InputProps> = ({
    // isPreviewMode = false, // Set default value for isPreviewMode
    autoComplete,
    isFormInput,
    description,
    titleColor,
    isEllipsis,
    isTouched,
    reference,
    error,
    title,
    refik,
    type,
    ...inputProps
}: InputProps) => {
    const [inputType, setInputType] = useState(type);
    const [showPassword, setShowPassword] = useToggle(false);
    const isPasswordType = useMemo(() => type === 'password', [type]);

    const onChangePasswordView = () => {
        setInputType(showPassword ? 'password' : 'text');
        setShowPassword();
    };

    useEffect(() => setInputType(type), [type]);

    return (
        <Container>
            {(error || title) && (
                <InputTitle color={titleColor} $isError={!!error}>
                    {error || title || ''}
                    {description && <DescriptionBadge description={description} />}
                </InputTitle>
            )}
            <Content>
                <StyledInput
                    ref={isFormInput ? refik : reference}
                    autoComplete={autoComplete}
                    // disabled={!isPreviewMode} // Conditionally apply disabled prop
                    isEllipsis={isEllipsis}
                    touched={isTouched}
                    type={inputType}
                    error={error}
                    {...inputProps}
                />
                {isPasswordType && (
                    <ShowPasswordIconContainer onClick={onChangePasswordView} role="button">
                        {showPassword ? <EyeIconOpen /> : <EyeIconClose />}
                    </ShowPasswordIconContainer>
                )}
            </Content>
        </Container>
    );
};
