import type { VariationItems } from 'types/builder/interface';

import { type SetStateAction, type ChangeEvent, type Dispatch, useCallback, useEffect } from 'react';
import { hideOrShowEvents } from 'stores/builder/hideOrShowItems';
import { MinusIconV2, PlusIcon } from 'ui-kit/assets';
import { type FC } from 'types/react';

import { ButtonsContainer, Container, Content, Button, Title } from './styles';
import { InputWithTooltip } from './InputWithTooltip';
import { Logic } from '../tabs/Logic/Logic';

const portal = document.createElement('div');
if (!document.body) {
    throw new Error('body not ready for portal creation!');
}
document.body.appendChild(portal);

export interface AddOrRemoveButtonProps {
    type?: 'remove' | 'add';
    itemType?: string;
    index: number;
}

interface Props {
    handleOnChangeAnswers: (props: { index: number; type: string }) => void;
    setVariationItems: Dispatch<SetStateAction<VariationItems[]>>;
    variationItems: VariationItems[];
    isDisabledEditInput: boolean;
    isShownLogicTab: boolean;
    selectedValue: string;
    blockId?: string;
}

export const VariationsEditor: FC<Props> = ({
    handleOnChangeAnswers,
    isDisabledEditInput,
    setVariationItems,
    isShownLogicTab,
    variationItems,
    selectedValue,
    blockId
}) => {
    const { initializeShowOrHide } = hideOrShowEvents;

    const handleChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        const newState = variationItems.map((item, i) => (i === index ? { ...item, text: value } : item));

        setVariationItems(newState);
    };

    const initialize = useCallback(() => {
        initializeShowOrHide(
            variationItems.map((item, index) => ({
                value: item.value.value || (index === 0 ? 'show' : 'hide'),
                key: index.toString()
            }))
        );
    }, [initializeShowOrHide, variationItems]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <>
            <Container>
                <Title>Answers</Title>
                {!!variationItems.length &&
                    variationItems.map(({ text, id }, index) => (
                        <Content key={id}>
                            <InputWithTooltip
                                isDisabledEditInput={isDisabledEditInput}
                                name={`radio_${index + 1}_text`}
                                handleChange={handleChange}
                                index={index}
                                text={text}
                            />
                            {!isDisabledEditInput && (
                                <ButtonsContainer>
                                    <Button
                                        onClick={() => {
                                            handleOnChangeAnswers({
                                                type: 'add',
                                                index
                                            });
                                        }}
                                        type="button"
                                    >
                                        <PlusIcon height={12} width={12} />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            handleOnChangeAnswers({
                                                type: 'remove',
                                                index
                                            });
                                        }}
                                    >
                                        <MinusIconV2 height={2} width={12} />
                                    </Button>
                                </ButtonsContainer>
                            )}
                        </Content>
                    ))}
            </Container>
            {isShownLogicTab && (
                <Logic variationItems={variationItems} selectedValue={selectedValue} blockId={blockId} />
            )}
        </>
    );
};
