import type { BlockInterface } from 'types/builder/interface';

import { replaceTextWithOffset } from 'components/builder/RichTextEditor/helpers/replace-text-with-offset';
import { createEvent, createStore, merge } from 'effector';

import type {
    AddContentEditorStateEventProps,
    ChangeDocumentNameEventProps,
    DeleteClauseVariationsProps,
    ChangeBlockTitleEventProps,
    ChangeShowClauseEventProps,
    DeleteBlockVariationsProps,
    ChangeShowBlockEventProps,
    AddClauseVariationsProps,
    AddBlockVariationsProps,
    AddParagraphEventProps,
    BlockReorderEventProps,
    FieldReorderEventProps,
    DeleteBlockEventProps,
    InitializeBlocksProps,
    AddBlockEventProps
} from './types';
import type { Data } from '../field/types';

import {
    addSigningBlockSectionToRowEvent,
    reorderSigningBlockFieldsInGroup,
    deleteSigningBlockSectionEvent,
    addSigningBlockRowEvent,
    addSigningBlockEvent
} from '../signingBlock/model';
import {
    makeClauseVariantIsDefaultEvent,
    deleteClauseVariantEvent,
    changeClauseTitleEvent,
    addClauseVariantEvent,
    deleteClauseEvent,
    addClauseEvent
} from '../clause';
import { deleteClauseVariationsReducer } from './reducers/delete-clause-variations-reducer';
import { addContentEditorStateReducer } from './reducers/add-content-editor-state-reducer';
import { addClauseVariationsReducer } from './reducers/add-clause-variations-reducer';
import { prepareFieldForUpdate } from '../field/helpers/prepare-field-for-update';
import { changeShowClauseReducer } from './reducers/change-show-clause-reducer';
import { addParagraphReducer } from './reducers/add-paragraph-reducer';
import { upfrontSummaryEvents } from '../upfrontSummary';
import { questionnaireEvents } from '../questionnaire';
import { updateFieldEvent } from '../field/model';
import { designEvents } from '../design';

// * events
export const addBlockEvent = createEvent<AddBlockEventProps>();
export const changeBlockTitleEvent = createEvent<ChangeBlockTitleEventProps>();
export const deleteBlockEvent = createEvent<DeleteBlockEventProps>();
export const addParagraphEvent = createEvent<AddParagraphEventProps>();
export const updateAllParagraphsEvent = createEvent<{ selectedField: any; payload: Data }>();
export const blockReorderEvent = createEvent<BlockReorderEventProps>();
export const fieldReorderEvent = createEvent<FieldReorderEventProps>();
export const addBlockVariationsEvent = createEvent<AddBlockVariationsProps>();
export const deleteBlockVariationsEvent = createEvent<DeleteBlockVariationsProps>();
export const addClauseVariationsEvent = createEvent<AddClauseVariationsProps>();
export const deleteClauseVariationsEvent = createEvent<DeleteClauseVariationsProps>();
export const initializeBlocksEvent = createEvent<InitializeBlocksProps>();
export const toggleNumberingEvent = createEvent();
export const changeShowBlockEvent = createEvent<ChangeShowBlockEventProps>();
export const changeShowClauseEvent = createEvent<ChangeShowClauseEventProps>();
export const changeDocumentNameEvent = createEvent<ChangeDocumentNameEventProps>();
export const addContentEditorStateEvent = createEvent<AddContentEditorStateEventProps>();
export const resetBlocksEvent = createEvent();
export const allEvents = merge([
    addBlockEvent,
    changeBlockTitleEvent,
    deleteBlockEvent,
    addParagraphEvent,
    blockReorderEvent,
    fieldReorderEvent,
    addBlockVariationsEvent,
    deleteBlockVariationsEvent,
    addClauseVariationsEvent,
    deleteClauseVariationsEvent,
    initializeBlocksEvent,
    toggleNumberingEvent,
    changeShowBlockEvent,
    changeShowClauseEvent,
    changeDocumentNameEvent,
    questionnaireEvents.addNewSubjectEvent,
    questionnaireEvents.changeSubjectTitleEvent,
    questionnaireEvents.reorderEvent,
    designEvents.changeActiveDesign,
    designEvents.changeDocumentFonts,
    designEvents.changeThemes,
    designEvents.changePalletIndex,
    changeClauseTitleEvent,
    addClauseVariantEvent,
    addClauseEvent,
    deleteClauseEvent,
    deleteClauseVariantEvent,
    makeClauseVariantIsDefaultEvent,
    toggleNumberingEvent,
    addSigningBlockRowEvent,
    addSigningBlockSectionToRowEvent,
    addSigningBlockEvent,
    deleteSigningBlockSectionEvent,
    reorderSigningBlockFieldsInGroup,
    upfrontSummaryEvents.addSectionToRowEvent,
    updateFieldEvent
]);

// * stores
export const $blocks = createStore<{
    blocks: BlockInterface[];
    documentName: string;
    isChanged: boolean;
}>({ documentName: '', isChanged: false, blocks: [] })
    .on(updateAllParagraphsEvent, (state, { selectedField, payload }) => ({
        ...state,
        blocks: state.blocks.map(block => ({
            ...block,
            groups: block.groups.map(group => ({
                ...group,
                fields: group.fields.map(field => ({
                    ...field,
                    content: field.content.map(content => {
                        let editorState = content.editorState;

                        const newContent = {
                            ...content,
                            paragraph: content.paragraph.map(item => {
                                if (item.placeholder?.trim() === selectedField.placeholder?.trim()) {
                                    const newField = prepareFieldForUpdate(
                                        { ...payload, entityProps: item.entityProps },
                                        field.id,
                                        content.id
                                    );

                                    if (editorState && newField.placeholder && selectedField.placeholder) {
                                        editorState = replaceTextWithOffset(
                                            editorState,
                                            item.entityProps.blockKey,
                                            item.entityProps.start,
                                            newField.placeholder,
                                            selectedField.placeholder.length
                                        );
                                    }

                                    return newField;
                                }

                                return item;
                            })
                        };

                        return { ...newContent, editorState };
                    })
                }))
            }))
        }))
    }))
    .on(allEvents, state => ({
        ...state,
        isChanged: true
    }))
    .on(changeDocumentNameEvent, (state, { documentName }) => ({ ...state, documentName }))
    .on(initializeBlocksEvent, state => ({
        ...state,
        documentName: '',
        blocks: []
    }))
    .on(addContentEditorStateEvent, addContentEditorStateReducer)
    .on(addBlockEvent, (state, { block }) =>
        state.blocks.length > 0
            ? { ...state, blocks: [...state.blocks, block] }
            : {
                  ...state,
                  documentName: '',
                  blocks: [block]
              }
    )
    .on(toggleNumberingEvent, state => ({
        ...state,
        blocks: state.blocks.map(block => ({
            ...block,
            withNumbering: !block.withNumbering
        }))
    }))
    .on(addBlockVariationsEvent, (state, { blockId, data }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId ? { ...block, variationsBlock: { ...data }, show: false } : block
        )
    }))
    .on(deleteBlockVariationsEvent, (state, { blockId }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId ? { ...block, variationsBlock: undefined, show: true } : block
        )
    }))
    .on(deleteClauseVariationsEvent, deleteClauseVariationsReducer)
    .on(addClauseVariationsEvent, addClauseVariationsReducer)
    .on(addParagraphEvent, addParagraphReducer)
    .on(deleteBlockEvent, (state, { blockId }) => ({
        ...state,
        blocks: state.blocks.filter(block => block.id !== blockId)
    }))
    .on(changeBlockTitleEvent, (state, { blockId, title }) => ({
        ...state,
        blocks: state.blocks.map(block => (block.id === blockId ? { ...block, title } : block))
    }))
    .on(blockReorderEvent, (state, { items }) => ({
        ...state,
        blocks: items
    }))
    .on(fieldReorderEvent, (state, { blockId, items }) => ({
        ...state,
        blocks: state.blocks.map(block => (block.id === blockId ? { ...block, groups: [{ fields: items }] } : block))
    }))
    .on(changeShowBlockEvent, (state, { blockId, show }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId && block.variationsBlock
                ? {
                      ...block,
                      show
                  }
                : block
        )
    }))
    .on(changeShowClauseEvent, changeShowClauseReducer)
    .on(resetBlocksEvent, () => ({
        documentName: '',
        isChanged: false,
        blocks: []
    }));
export const $clauses = $blocks.map(item => item.blocks.flatMap(item => item.groups).flatMap(group => group.fields));

export const $paragraphs = $blocks.map(item =>
    item.blocks
        .flatMap(item => item.groups)
        .flatMap(group => group.fields)
        .flatMap(field => field.content)
        .flatMap(item => item.paragraph)
        .flat()
);

$paragraphs.watch(a => console.log(a));
