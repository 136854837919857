import type { RawDraftContentState } from 'draft-js';

export const replaceTextWithOffset = (
    data: RawDraftContentState,
    blockKey: string,
    offset: number,
    newText: string,
    prevLength: number
): RawDraftContentState => ({
    blocks: data.blocks.map(block => {
        if (block.key === blockKey) {
            let text = '';
            const before = block.text.substring(0, offset);
            let after = '';
            after = block.text.substring(offset + prevLength);
            text = before + newText + after;

            return {
                ...block,
                entityRanges: block.entityRanges.map(entityRange => {
                    if (entityRange.offset > offset) {
                        const diff = newText.length - prevLength;

                        return {
                            ...entityRange,
                            offset: entityRange.offset + diff
                        };
                    }

                    if (entityRange.offset === offset) {
                        return {
                            ...entityRange,
                            length: newText.length
                        };
                    }

                    return entityRange;
                }),
                text
            };
        }

        return block;
    }),
    entityMap: data.entityMap
});
