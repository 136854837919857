import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import { PURPLE_60 } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

export const Button = styled.button<{ $isActive: boolean }>`
    ${disableDefaultButtonStyleMixin}
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #f1f1f1;

    &:hover {
        border-color: ${PURPLE_60};
    }

    ${({ $isActive }) =>
        $isActive &&
        css`
            border-color: ${PURPLE_60};
        `}
`;

export const StyledFieldButton = styled.button<{ $disabled: boolean }>`
    ${disableDefaultButtonStyleMixin}
    height: 30px;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #f1f1f1;
    padding: 8px;

    ${({ $disabled }) =>
        $disabled &&
        css`
            cursor: default;
            pointer-events: none;
            opacity: 0.5;
        `}

    &:hover {
        border-color: ${PURPLE_60};
    }
`;
