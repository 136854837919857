import {
    checkEmailForExistenceFx,
    updateUserPasswordFx,
    recoverPasswordFx,
    changePasswordFx,
    changeUserRoleFx,
    verifyAccountFx,
    createUserFx,
    updateUserFx,
    loadTokenFx,
    getMeFx
} from 'stores/user/model';
import {
    createOwnTemplateFx,
    deleteOwnTemplateFx,
    updateOwnTemplateFx,
    getOwnTemplatesFx,
    getOwnTemplateFx
} from 'stores/ownTemplates/model';
import { getDraftforGuestUserFx, createDraftFx, deleteDraftFx, getDraftsFx, getDraftFx } from 'stores/drafts/model';
import { getSingleDocumentFx, createDocumentFx, deleteDocumentFx, getDocumentsFx } from 'stores/documents/model';
import { sendQuestionForSubScriptionFx, sendQuestionFx } from 'stores/contact/model';
import { copyTemplateFx } from 'stores/copyTemplates/model';
import { createSubscriptionFx } from 'stores/mollie/model';
import { companyEffects } from 'stores/company';
import { pdfEffects } from 'stores/pdf/model';
import { roleEffects } from 'stores/role';
import { pending } from 'patronum';

// * stores
export const $isLoading = pending([
    ...Object.values({
        ...companyEffects,
        ...pdfEffects,
        ...roleEffects
    }),
    createOwnTemplateFx,
    deleteOwnTemplateFx,
    updateOwnTemplateFx,
    getOwnTemplatesFx,
    getOwnTemplateFx,
    createDraftFx,
    deleteDraftFx,
    getDraftFx,
    getDraftforGuestUserFx,
    getDraftsFx,
    sendQuestionFx,
    loadTokenFx,
    verifyAccountFx,
    createUserFx,
    checkEmailForExistenceFx,
    recoverPasswordFx,
    updateUserFx,
    updateUserPasswordFx,
    getMeFx,
    changeUserRoleFx,
    changePasswordFx,
    copyTemplateFx,
    createSubscriptionFx,
    getSingleDocumentFx,
    createDocumentFx,
    deleteDocumentFx,
    getDocumentsFx,
    sendQuestionForSubScriptionFx
]);
