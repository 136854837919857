import type { FC } from 'types/react';

import { type DraftEditorCommand, type DraftHandleValue, type EditorState, RichUtils, Editor } from 'draft-js';
import { type MouseEventHandler, type KeyboardEvent, useState, useRef } from 'react';
import { useClickAway } from 'react-use';

import { EditorContainer, StyledButton, Container } from './styles';
import { customStyleMap } from './constants';
import { Toolbar } from './ToolBar/ToolBar';

interface Props {
    onEditorStateChange: (editorState: EditorState) => void;
    onSave?: MouseEventHandler<HTMLButtonElement>;
    onFieldButtonClick?: () => void;
    editorState: EditorState;
    forDescription?: boolean;
    canAddField?: boolean;
    placeholder?: string;
    disabled?: boolean;
}

export const RichTextEditor: FC<Props> = ({
    onEditorStateChange,
    canAddField = true,
    onFieldButtonClick,
    forDescription,
    editorState,
    placeholder,
    disabled,
    onSave
}) => {
    const editorRef = useRef<Editor>(null);
    const [isFocused, setIsFocused] = useState(false);

    const handleKeyCommand = (command: DraftEditorCommand) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onEditorStateChange(newState);
            return 'handled';
        }

        return 'not-handled';
    };

    const handleReturn = (event: KeyboardEvent, editorState: EditorState): DraftHandleValue => {
        if (event.key === 'Enter') {
            onEditorStateChange(RichUtils.insertSoftNewline(editorState));

            return 'handled';
        }

        return 'not-handled';
    };

    const onFocus = () => {
        if (editorRef.current) {
            editorRef.current.focus();
            setIsFocused(true);
        }
    };

    const withSaveButton = onSave !== undefined;

    const containerRef = useRef<HTMLDivElement>(null);

    useClickAway(containerRef, () => {
        if (editorRef.current) {
            editorRef.current.blur();
            setIsFocused(false);
        }
    });

    return (
        <Container ref={containerRef}>
            {withSaveButton && (
                <StyledButton variant="primary" onClick={onSave}>
                    Save
                </StyledButton>
            )}
            {(withSaveButton || forDescription) && (
                <Toolbar
                    onEditorStateChange={onEditorStateChange}
                    onFieldButtonClick={onFieldButtonClick}
                    canAddField={canAddField}
                    editorState={editorState}
                    isFocused={isFocused}
                    disabled={disabled}
                />
            )}
            <EditorContainer $withSaveButton={withSaveButton} $disabled={disabled} onClick={onFocus}>
                <Editor
                    placeholder={placeholder || 'Enter a text...'}
                    handleKeyCommand={handleKeyCommand}
                    customStyleMap={customStyleMap}
                    onChange={onEditorStateChange}
                    handleReturn={handleReturn}
                    editorState={editorState}
                    readOnly={disabled}
                    ref={editorRef}
                />
            </EditorContainer>
        </Container>
    );
};
