import type { EditorState } from 'draft-js';

export const getEntityKeys = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();

    const blockMap = contentState.getBlockMap();

    const entityKeys: string[] = [];

    blockMap.forEach(block => {
        block?.findEntityRanges(
            character => {
                const entityKey = character.getEntity();

                return entityKey !== null;
            },
            start => {
                const entityKey = block.getEntityAt(start);

                if (!entityKeys.includes(entityKey)) {
                    entityKeys.push(entityKey);
                }
            }
        );
    });

    return entityKeys;
};
