import type { RadioValue } from 'types/builder/interface';
import type { SingleValue } from 'react-select';
import type { FC } from 'types/react';

import { hideOrShowEvents, hideOrShowStores } from 'stores/builder/hideOrShowItems';
import { type SelectValue, Select } from 'ui-kit/components/Select/Select';
import { Input } from 'ui-kit/components/inputs/Input';
import { useUnit } from 'effector-react';

import { getDefaultShowOrHideString } from '../../../VariationsFieldModal/helpers/get-default-show-or-hide-string';
import { SelectContainer, Container, Content, Title, Item, Hr } from './styles';
import { selectOptions } from './constants';
import { $question } from '../../model';
import { Answer } from './Answer';

interface Props {
    variationItems: RadioValue[];
    selectedValue: string;
    blockId?: string;
}

export const Logic: FC<Props> = ({ variationItems }) => {
    const { setShowOrHide } = hideOrShowEvents;
    const question = useUnit($question);
    const showOrHide = useUnit(hideOrShowStores.showOrHide);

    const handleSelectShowOrHide = (value: string, key: string) => {
        setShowOrHide({ value, key });
    };

    return (
        <Container>
            <Hr />
            {variationItems.map((item, index) => {
                const showOrHideDefaultValue = getDefaultShowOrHideString(showOrHide[index]?.value);

                return (
                    <Content key={index}>
                        <Item>
                            <Title>
                                <b>IF</b> the answer to:
                            </Title>
                            <Answer text={question} />
                        </Item>
                        <Item>
                            <Title>IS:</Title>
                            <Answer text={item.text} />
                            <Title>THEN:</Title>
                            <SelectContainer>
                                <Select
                                    onChange={value => {
                                        const newValue = value as SingleValue<SelectValue>;
                                        handleSelectShowOrHide(newValue?.label || '', index.toString());
                                    }}
                                    defaultValue={showOrHideDefaultValue}
                                    options={selectOptions}
                                />
                            </SelectContainer>
                            <Input value="article" type="text" disabled />
                        </Item>
                    </Content>
                );
            })}
        </Container>
    );
};
