import type { FC } from 'types/react';

import { StyledFieldButton } from './styles';
import { PlusIcon } from './static_assets';

interface Props {
    onClick?: () => void;
    disabled: boolean;
}

export const FieldButton: FC<Props> = ({ disabled, onClick }) => (
    <StyledFieldButton $disabled={disabled} onClick={onClick}>
        <PlusIcon />
        Field
    </StyledFieldButton>
);
