import type { Data } from 'stores/builder/field/types';

import { setCurrentFieldsEvent } from 'stores/builder/field/model';
import { FieldTypesEnum } from 'constants/builder/enums';
import { v4 as generateId } from 'uuid';

import type { FieldItem } from '../SelectMultiSelectRadioEditor';

export const createRadioField = (
    radioItems: FieldItem[],
    selectedField: undefined | Data,
    currentFields: Data[],
    question: string,
    description: string,
    placeholder: string,
    contentId: string,
    existingFieldId?: string
) => {
    const generatedSingleRadioItems = radioItems.map(item => ({
        value: item.value,
        id: generateId(),
        text: item.text,
        type: 'Text'
    }));

    setCurrentFieldsEvent(
        selectedField
            ? {
                  currentFields: currentFields.map(item =>
                      item.id === selectedField.id
                          ? {
                                singleRadioItems: generatedSingleRadioItems,
                                type: FieldTypesEnum.Radio,
                                description,
                                placeholder,
                                value: '',
                                contentId,
                                question,
                                id: ''
                            }
                          : item
                  ),
                  contentId
              }
            : {
                  currentFields: [
                      ...currentFields,
                      {
                          singleRadioItems: generatedSingleRadioItems,
                          type: FieldTypesEnum.Radio,
                          id: existingFieldId || '',
                          description,
                          placeholder,
                          value: '',
                          contentId,
                          question
                      }
                  ],
                  contentId
              }
    );
};
