import { type ButtonHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { type FC } from 'types/react';

import {
    StyleChangeDocumentButton,
    StyledShowCommentButton,
    StyledAddEmailButton,
    StyledMessageButton,
    StyledButton,
    ButtonContainer
} from './styles';

export type ButtonVariant = 'secondary' | 'primary' | 'light' | 'link';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant: ButtonVariant;
}

// email add button wrapper
const IconWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.67px;
    border: 2px solid #0014cb;
    border-radius: 3px;
    width: 15px;
    height: 15px;
`;
// Chat  button wrapper
const MessageIconWrapper = styled.div<{ isActive: boolean }>`
    padding: 6px;
    border-radius: 3px;
    width: 44px;
    height: 44px;
    background-color: ${({ isActive }) => (isActive ? 'blue' : 'transparent')}; /* Blue background when active */
    color: ${({ isActive }) => (isActive ? 'white' : 'black')}; /* White color when active */
`;

// SVG  chat icon
function ChatIcon() {
    return (
        <svg
            transform="matrix(-1, 0, 0, 1, 0, 0)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2.4 -2.4 28.80 28.80"
            height="32"
            fill="none"
            width="32"
        >
            <g id="SVGRepo_bgCarrier" stroke-width="0">
                <rect stroke-width="0" height="28.80" fill="#0015CB" width="28.80" rx="5.76" x="-2.4" y="-2.4"></rect>
            </g>
            <g id="SVGRepo_tracerCarrier" stroke-linejoin="round" stroke-linecap="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 13.7183 1.65371 15.3445 2.37213 16.7869C2.47933 17.0021 2.50208 17.2219 2.4526 17.4068L1.857 19.6328C1.44927 21.1566 2.84337 22.5507 4.3672 22.143L6.59324 21.5474C6.77814 21.4979 6.99791 21.5207 7.21315 21.6279C8.65553 22.3463 10.2817 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12ZM12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C10.5189 21.25 9.12121 20.9025 7.88191 20.2852C7.38451 20.0375 6.78973 19.9421 6.20553 20.0984L3.97949 20.694C3.57066 20.8034 3.19663 20.4293 3.30602 20.0205L3.90163 17.7945C4.05794 17.2103 3.96254 16.6155 3.7148 16.1181C3.09752 14.8788 2.75 13.4811 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75Z"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#ffffff"
                />
            </g>
        </svg>
    );
}
// SVG icon or use an icon from a library
function PlusIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            height="14"
            fill="blue" // This allows the color to inherit from the parent
            width="14"
        >
            <path
                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2z"
                fillRule="evenodd" // Correcting the attribute to camelCase for React
            />
        </svg>
    );
}
export const uploadIcon = () => {
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="bi bi-upload"
        fill="currentColor"
        viewBox="0 0 16 16"
        height="16"
        width="16"
    >
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
    </svg>;
};

export const Button: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => (
    <StyledButton disabled={disabled} variant={variant} {...rest}>
        {children}
    </StyledButton>
);

export const AddEmailButton: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => (
    <StyledAddEmailButton disabled={disabled} variant={variant} {...rest}>
        <IconWrapper>
            <PlusIcon />
        </IconWrapper>
        <span style={{ marginLeft: '5px' }}>{children}</span>
    </StyledAddEmailButton>
);

// Chat Icon Button
export const MessageButton: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => {
    const [isActive, setIsActive] = useState(false); // State to manage active status
    const handleClick = () => {
        setIsActive(prev => !prev); // Toggle active state on click
        console.log('_-set is', setIsActive);
    };
    return (
        <StyledMessageButton onClick={handleClick} disabled={disabled} variant={variant} {...rest}>
            <MessageIconWrapper style={{ padding: '0 !important', cursor: 'pointer' }} isActive={isActive}>
                <ChatIcon />
            </MessageIconWrapper>
            {/* {children} */}
            <span>{children}</span>
        </StyledMessageButton>
    );
};

// Show comment Button
export const ShowCommentButton: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => (
    <StyledShowCommentButton disabled={disabled} variant={variant} {...rest}>
        {children}
    </StyledShowCommentButton>
);

//Change Documents
export const ChangeDocumentButton: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => (
    <StyleChangeDocumentButton disabled={disabled} variant={variant} {...rest}>
        <ButtonContainer>{children}</ButtonContainer>
    </StyleChangeDocumentButton>
);
