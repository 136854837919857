import { initializeGenericStore, initializeStoreModal } from 'utils/store';
import { CopyTemplateEnum } from 'constants/enums';
import { $alertStore } from 'stores/alerts/model';
import { combine } from 'effector';

import type {
    UpgradeProfessionalConfirmationModalStore,
    DowngradeConfirmationModalStore,
    CompanyTemplateNamingModalStore,
    CompanyUserChangeModalStore,
    DeleteCompanyUserModalStore,
    VariationsFieldModalStore,
    VariationsBlockModalStore,
    CopyTemplateModalStore,
    CopyDraftModalStore,
    LeaveBuilderModalStore,
    EmailSuccessModalStore,
    ColorPalletModalStore,
    DescriptionModalStore,
    AreYouSureModalStore,
    ComingSoonModalStore,
    LeaveDraftModalStore,
    DocumentsModalStore,
    TemplateModalStore,
    AddUserModalStore,
    FieldModalStore,
    DraftModalStore,
    ShareModalStore,
    SubjectType,
    DownloadDocumentModalStore
} from './types';
// import { ReplyModal } from 'components/modals';

// * simple modals stores
export const notAuthorizedModalStore = initializeStoreModal();
export const companyUserChangeModal = initializeStoreModal();
export const deleteCompanyUserModal = initializeStoreModal();
export const cookiesModalStore = initializeStoreModal();
// export const leaveDraftModalStore = initializeStoreModal(); //draft
export const leaveDocumentsModalStore = initializeStoreModal();
export const leaveOwnTemplateModalStore = initializeStoreModal();
export const createReminderModalStore = initializeStoreModal();
export const fontsModalStore = initializeStoreModal();
export const loginModalStore = initializeStoreModal();
export const previewModeModalStore = initializeStoreModal();

// * generic modals stores
export const areYouSureModalStore = initializeGenericStore<AreYouSureModalStore>({
    handleSubmit: () => {},
    title: '',
    text: ''
});
export const updateSubscriptionModalStore = initializeGenericStore<{ subtitleText: string }>({ subtitleText: '' });
export const companyTemplateNamingModalStore = initializeGenericStore<CompanyTemplateNamingModalStore>({});
export const companyUserChangeModalStore = initializeGenericStore<CompanyUserChangeModalStore>({
    user: undefined
});
export const deleteCompanyUserModalStore = initializeGenericStore<DeleteCompanyUserModalStore>({
    handleDeleteCompanyUser: _id => {},
    id: ''
});
export const descriptionModalStore = initializeGenericStore<DescriptionModalStore>({
    backgroundWidth: '',
    description: ''
});
export interface RemoveTrendingModal {
    subject?: SubjectType;
    subjectName?: string;
    id?: string;
}
export const leaveBuilderModalStore = initializeGenericStore<LeaveBuilderModalStore>({
    isUpdatingTemplate: false,
    isUpdatingDraft: false,
    // isUpdatingDocument: false,
    documentName: '',
    config: {}
});
export const leaveDraftModalStore = initializeGenericStore<LeaveDraftModalStore>({
    // isUpdatingTemplate: false,
    isUpdatingDraft: false,
    // isUpdatingDocument: false,
    documentName: '',
    config: {}
});
export const comingSoonModalStore = initializeGenericStore<ComingSoonModalStore>({ contractName: '' });
export const addUserModalStore = initializeGenericStore<AddUserModalStore>({ title: '' });
export const templateModalStore = initializeGenericStore<TemplateModalStore>({
    documentName: '',
    config: {}
});
// open share model
export const shareModalStore = initializeGenericStore<ShareModalStore>({
    documentName: '',
    config: {}
});

export const downloadDocumnetModalStore = initializeGenericStore<DownloadDocumentModalStore>({
    documentName: '',
    config: {}
});
//Reply Modal
// export const replyModalStore = initializeGenericStore<ReplyModalStore>({
//     documentName: '',
//     config: {}
// });
export const documentsModalStore = initializeGenericStore<DocumentsModalStore>({
    //douments
    documentName: '',
    config: {}
});
export const draftModalStore = initializeGenericStore<DraftModalStore>({
    documentName: '',
    config: {}
});

export const variationsFieldModalStore = initializeGenericStore<VariationsFieldModalStore>({});
export const variationsBlockModalStore = initializeGenericStore<VariationsBlockModalStore>({});
export const fieldModalStore = initializeGenericStore<FieldModalStore>({ contentId: '' });
export const colorPalletModalStore = initializeGenericStore<ColorPalletModalStore>({
    palletToUpdate: {
        second: '',
        fourth: '',
        first: '',
        third: ''
    }
});
export const copyTemplateModalStore = initializeGenericStore<CopyTemplateModalStore>({
    from: CopyTemplateEnum.Kiara,
    templateId: ''
});
export const copyDraftModalStore = initializeGenericStore<CopyDraftModalStore>({
    templateName: '',
    draftId: ''
});

export const upgradeProfessionalConfirmationStore = initializeGenericStore<UpgradeProfessionalConfirmationModalStore>({
    handleUpgrade: () => {},
    billingCycle: '',
    isMonth: true,
    title: '',
    price: '',
    text: ''
});

export const downgradeConfirmationStore = initializeGenericStore<DowngradeConfirmationModalStore>({
    handleDowngrade: () => {},
    title: '',
    text: ''
});

export const emailSuccessModalStore = initializeGenericStore<EmailSuccessModalStore>({
    title: '',
    text: ''
});
// * combined modals store
export const combinedModalsStore = combine(
    {
        upgradeProfessionalConfirmationStore: upgradeProfessionalConfirmationStore.modal,
        companyTemplateNamingModalStore: companyTemplateNamingModalStore.modal,
        updateSubscriptionModalStore: updateSubscriptionModalStore.modal,
        downgradeConfirmationStore: downgradeConfirmationStore.modal,
        leaveOwnTemplateModalStore: leaveOwnTemplateModalStore.modal,
        downloadDocumnetModalStore: downloadDocumnetModalStore.modal,
        variationsBlockModalStore: variationsBlockModalStore.modal,
        variationsFieldModalStore: variationsFieldModalStore.modal,
        createReminderModalStore: createReminderModalStore.modal,
        leaveDocumentsModalStore: leaveDocumentsModalStore.modal, //document
        notAuthorizedModalStore: notAuthorizedModalStore.modal,
        copyTemplateModalStore: copyTemplateModalStore.modal,
        leaveBuilderModalStore: leaveBuilderModalStore.modal,
        emailSuccessModalStore: emailSuccessModalStore.modal,
        colorPalletModalStore: colorPalletModalStore.modal,
        descriptionModalStore: descriptionModalStore.modal,
        previewModeModalStore: previewModeModalStore.modal,
        areYouSureModalStore: areYouSureModalStore.modal,
        comingSoonModalStore: comingSoonModalStore.modal,
        leaveDraftModalStore: leaveDraftModalStore.modal,
        copyDraftModalStore: copyDraftModalStore.modal,
        templateModalStore: templateModalStore.modal,
        addUserModalStore: addUserModalStore.modal,
        cookiesModalStore: cookiesModalStore.modal,
        shareModelStore: shareModalStore.modal, // share model
        //    replyModalStore: replyModalStore.modal, // reply modal Store
        fieldModalStore: fieldModalStore.modal,
        fontsModalStore: fontsModalStore.modal,
        alertsStores: $alertStore
    },
    ({
        upgradeProfessionalConfirmationStore,
        companyTemplateNamingModalStore,
        updateSubscriptionModalStore,
        downgradeConfirmationStore,
        leaveOwnTemplateModalStore,
        downloadDocumnetModalStore,
        variationsBlockModalStore,
        variationsFieldModalStore,
        createReminderModalStore,
        leaveDocumentsModalStore,
        notAuthorizedModalStore,
        copyTemplateModalStore,
        leaveBuilderModalStore,
        emailSuccessModalStore,
        colorPalletModalStore,
        descriptionModalStore,
        previewModeModalStore,
        areYouSureModalStore,
        comingSoonModalStore,
        leaveDraftModalStore,
        copyDraftModalStore,
        templateModalStore,
        addUserModalStore,
        cookiesModalStore,
        shareModelStore, // share model store
        // replyModalStore, // Reply Modal Store
        fieldModalStore,
        fontsModalStore,
        alertsStores
    }) => ({
        isUpgradeProfessionalConfirmationModalVisible: upgradeProfessionalConfirmationStore[0],
        isCompanyTemplateNamingModalVisible: companyTemplateNamingModalStore[0],
        isDowngradeConfirmationModalVisible: downgradeConfirmationStore[0],
        isLeaveOwnTemplateModalVisible: leaveOwnTemplateModalStore.visible,
        isUpdateSubscriptionModalVisible: updateSubscriptionModalStore[0],
        isCreateReminderModalVisible: createReminderModalStore.visible,
        isLeaveDocumentModalVisible: leaveDocumentsModalStore.visible,
        isNotAuthorizedModalVisible: notAuthorizedModalStore.visible,
        isVariationsFieldModalVisible: variationsFieldModalStore[0],
        isVariationsBlockModalVisible: variationsBlockModalStore[0],
        isPreviewModeModalVisible: previewModeModalStore.visible,
        isDownloadDocumentVisible: downloadDocumnetModalStore[0],
        isEmailSuccessModalVisible: emailSuccessModalStore[0],
        isLeaveBuilderModalVisible: leaveBuilderModalStore[0],
        isCopyTemplateModalVisible: copyTemplateModalStore[0],
        isColorPalletModalVisible: colorPalletModalStore[0],
        isDescriptionModalVisible: descriptionModalStore[0],
        isAlertsModalVisible: Boolean(alertsStores.active),
        isLeaveDraftModalVisible: leaveDraftModalStore[0],
        isAreYouSureModalVisible: areYouSureModalStore[0],
        isComingSoonModalVisible: comingSoonModalStore[0],
        isCookiesModalVisible: cookiesModalStore.visible,
        isCopyDraftModalVisible: copyDraftModalStore[0],
        isTemplateModalVisible: templateModalStore[0],
        isFontsModalVisible: fontsModalStore.visible,
        isAddUserModalVisible: addUserModalStore[0],
        isShareModalVisible: shareModelStore[0], //Share modal store
        // isReplyModalVisible: replyModalStore[0], // Reply Modal Store
        isFieldModalVisible: fieldModalStore[0]
    })
);
