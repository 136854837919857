import type { FC } from 'types/react';

import { emailSuccessModalStore } from 'stores/modals/model';
import { Modal } from 'ui-kit/components/Modal';
import { useUnit } from 'effector-react';

import { BodyContainer, CloseButton, Title, Text } from './styles';
import { EmailSuccess } from './static_assets';

export const EmailSuccessModal: FC = () => {
    const { closeModal, modal } = emailSuccessModalStore;
    const [_, { title, text }] = useUnit(modal);

    return (
        <Modal visible>
            <CloseButton
                onClick={() => {
                    closeModal();
                }}
            >
                X
            </CloseButton>
            <BodyContainer>
                <EmailSuccess />
                <Title>{title}</Title>
                <Text>{text}</Text>
            </BodyContainer>
        </Modal>
    );
};
