import { createNotifyingErrorEffect } from 'utils/store';
import { createStore } from 'effector';
import { API } from 'api';

// * effects
export const getSubscriptionsFx = createNotifyingErrorEffect({
    handler: async () => API.mollie.getSubscriptions()
});

export const createSubscriptionFx = createNotifyingErrorEffect({
    handler: async (data: KIARA_API.MollieSubscriptionRequest) => await API.mollie.createSubscription(data)
});

export const cancelSubscriptionFx = createNotifyingErrorEffect({
    handler: async (data: KIARA_API.MollieSubscriptionRequest) => await API.mollie.cancelSubscription(data)
});

// * stores
export const $subscriptions = createStore<KIARA_API.MollieSubscriptionResponse[]>([]).on(
    getSubscriptionsFx.doneData,
    (_, payload) => payload
);

export const $subscriptionsWithoutProfessional = $subscriptions.map(subscriptions =>
    subscriptions.filter(({ type }) => type !== 'professional')
);

export const $professionalSubscriptions = $subscriptions.map(subscriptions =>
    subscriptions.filter(({ type }) => type === 'professional')
);
