import { WHITE } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const Footer = styled.div`
    width: 100%;
    background-color: ${WHITE};
    display: flex;
    justify-content: flex-end;
    gap: 16px;
`;
