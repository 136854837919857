import { EditorState } from 'draft-js';
import { useMemo } from 'react';

export const useSelectionText = (editorState: EditorState) => {
    const text = useMemo(() => {
        const selectionState = editorState.getSelection();
        EditorState.forceSelection(editorState, selectionState);
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();

        return currentContentBlock.getText().slice(start, end);
    }, [editorState]);

    return text;
};
