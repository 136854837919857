import {
    ActivePreviewDesign,
    type PreviewThemes,
    type PreviewFonts,
    ActivePalletIndex,
    type PreviewInfo
} from 'types/preview';
import { previewThemes } from 'pages/Magic/Steps/constants';
import { createEvent, createStore } from 'effector';

// * events
export const changeActiveDesign = createEvent<number>();
export const changeThemes = createEvent<PreviewThemes>();
export const changeDocumentFonts = createEvent<PreviewFonts>();
export const changePalletIndex = createEvent<ActivePalletIndex>();
export const resetCoverStyles = createEvent();

// * stores
export const $coverStyles = createStore<PreviewInfo>({
    documentFonts: {
        heading: '',
        body: ''
    },
    activeDesign: ActivePreviewDesign.First,
    palletIndex: ActivePalletIndex.First,
    themes: previewThemes
}).reset(resetCoverStyles);

$coverStyles
    .on(changeThemes, (data: PreviewInfo, payload: PreviewThemes) => ({
        ...data,
        themes: payload
    }))
    .on(changePalletIndex, (data: PreviewInfo, payload: ActivePalletIndex) => ({
        ...data,
        palletIndex: payload
    }))
    .on(changeActiveDesign, (data: PreviewInfo, payload: ActivePreviewDesign) => ({
        ...data,
        activeDesign: payload
    }))
    .on(changeDocumentFonts, (data: PreviewInfo, payload: PreviewFonts) => ({
        ...data,
        documentFonts: payload
    }));

// * exports
export const designEvents = {
    changeDocumentFonts,
    changeActiveDesign,
    changePalletIndex,
    resetCoverStyles,
    changeThemes
};
