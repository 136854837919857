import axios from 'api/axios';

export const createDraft = (data: KIARA_API.UserDraftsRequest) =>
    axios<{
        isDeleted: boolean;
        createdAt: string;
        deletedAt: string;
        updatedAt: string;
        draft: string;
        user: string;
        id: string;
    }>({
        url: 'user-drafts',
        data
    });

export const updateDraft = (data: KIARA_API.UserDraftsRequest, id: string) =>
    axios<API.ErrorResponse>({
        url: `user-drafts/${id}`,
        method: 'PATCH',
        data
    });

export const getDrafts = () =>
    axios<KIARA_API.UserDraftsResponse[]>({
        url: 'user-drafts/my',
        method: 'GET'
    });

export const getDraft = (id: string) =>
    axios<KIARA_API.UserDraftsResponse>({
        url: `user-drafts/${id}`,
        method: 'GET'
    });
// Public for guest user
export const getDraftGuest = (id: string) =>
    axios<KIARA_API.UserDraftsResponse>({
        url: `user-drafts/guestDraft/${id}`,
        method: 'GET'
    });

// request access API user send his document link through this API
export const sendRequestAccess = (draftId: string, formData: FormData) =>
    axios({
        headers: {
            'Content-Type': 'multipart/form-data' // Set content type if necessary
        },
        url: `guest-users/request_access/${draftId}`,
        method: 'POST',
        data: formData
    });

export const getDraftforGuestUser = (token: string, draftId: string, email: string) =>
    axios<KIARA_API.UserDraftsResponse>({
        headers: {
            Authorization: `Bearer ${token}` // Token in Authorization header
        },
        url: `guest-users/access/${draftId}?email=${encodeURIComponent(email)}`,
        method: 'GET'
    });
export const deleteDraft = (id: string) =>
    axios<KIARA_API.SuccessAnswer>({
        url: `user-drafts/${id}`,
        method: 'DELETE'
    });
