import { $coverStyles } from 'stores/builder/design';
import { useUnit } from 'effector-react';
import { useMemo } from 'react';

const replace = (font: string) => font.replace(', sans-serif', '');

export const useDefaultFonts = () => {
    const { documentFonts } = useUnit($coverStyles);

    const fontPairingDefaultValue = useMemo(() => {
        const { heading, body } = documentFonts;

        if (!body || !heading) {
            return undefined;
        }

        return { label: `${replace(heading)} + ${replace(body)}`, value: `${replace(heading)} + ${replace(body)}` };
    }, [documentFonts]);

    const headingDefaultValue = useMemo(() => {
        const { heading } = documentFonts;

        if (!heading) {
            return undefined;
        }

        return { label: replace(heading), value: replace(heading) };
    }, [documentFonts]);

    const bodyDefaultValue = useMemo(() => {
        const { body } = documentFonts;

        if (!body) {
            return undefined;
        }

        return { label: replace(body), value: replace(body) };
    }, [documentFonts]);

    return {
        fontPairingDefaultValue,
        headingDefaultValue,
        bodyDefaultValue
    };
};
