import { setIsLoadingTemplatePagesEvent, setMountingEvent } from 'stores/templatePages/model';
import { getOwnTemplateFx } from 'stores/ownTemplates/model';
import { getDraftFx } from 'stores/drafts/model';
import { sample } from 'effector';

import {
    deleteClauseVariationsEvent,
    deleteBlockVariationsEvent,
    addClauseVariationsEvent,
    addBlockVariationsEvent,
    changeShowClauseEvent,
    changeShowBlockEvent,
    addParagraphEvent,
    deleteBlockEvent
} from '../block/model';
import { deleteClauseVariationsHelper } from './helpers/delete-clause-variations-helper';
import { deleteBlockVariationsHelper } from './helpers/delete-block-variations-helper';
import { addClauseVariationsHelper } from './helpers/add-clause-variations-helper';
import { deleteClauseVariantHelper } from './helpers/delete-clause-variant-helper';
import { addBlockVariationsHelper } from './helpers/add-block-variations-helper';
import { deleteClauseVariantEvent, deleteClauseEvent } from '../clause';
import { deleteSigningBlockSectionEvent } from '../signingBlock/model';
import { deleteClauseHelper } from './helpers/delete-clause-helper';
import { addParagraphHelper } from './helpers/add-paragraph-helper';
import { questionnaireEvents, questionnaireStores } from './index';
import { updateFieldEvent } from '../field/model';
import { designEvents } from '../design';

questionnaireStores.questionnaireItems.on(updateFieldEvent, (state, { newField, fieldId }) => ({
    ...state,
    questions: state.questions.map(question =>
        question.fieldId === fieldId
            ? {
                  ...question,
                  description: newField.description,
                  title: newField.placeholder || '',
                  subtitle: newField.question
              }
            : question
    )
}));

// * block
questionnaireStores.questionnaireItems
    .on(deleteBlockEvent, (state, { blockId }) => ({
        ...state,
        questions: state.questions.filter(question => question.blockId !== blockId)
    }))
    .on(addBlockVariationsEvent, (state, { blockId, data }) => ({
        ...state,
        questions: addBlockVariationsHelper(state.questions, blockId, data)
    }))
    .on(deleteBlockVariationsEvent, (state, { blockId }) => ({
        ...state,
        questions: deleteBlockVariationsHelper(state.questions, blockId)
    }))
    .on(addClauseVariationsEvent, (state, { clauseId, blockId, data }) => ({
        ...state,
        questions: addClauseVariationsHelper(state.questions, blockId, clauseId, data)
    }))
    .on(deleteClauseVariationsEvent, (state, { clauseId }) => ({
        ...state,
        questions: deleteClauseVariationsHelper(state.questions, clauseId)
    }))
    .on(addParagraphEvent, (state, { paragraphs, contentId, clauseId, blockId, isShown }) => ({
        ...state,
        questions: addParagraphHelper(state.questions, paragraphs, clauseId, blockId, contentId, isShown)
    }));

// * signing block
questionnaireStores.questionnaireItems.on(deleteSigningBlockSectionEvent, (state, { fieldId }) => ({
    ...state,
    questions: deleteClauseHelper(state.questions, fieldId)
}));

// * clause
questionnaireStores.questionnaireItems
    .on(deleteClauseEvent, (state, { fieldId }) => ({
        ...state,
        questions: deleteClauseHelper(state.questions, fieldId)
    }))
    .on(deleteClauseVariantEvent, (state, { contentId }) => ({
        ...state,
        questions: deleteClauseVariantHelper(state.questions, contentId)
    }));

// * effects
questionnaireStores.questionnaireItems
    .on(getOwnTemplateFx.doneData, (_, { templateConfig: { questionnaire } }) => ({
        questions: questionnaire
    }))
    .on(
        getDraftFx.doneData,
        (
            _,
            {
                draft: {
                    templateConfig: { questionnaire }
                }
            }
        ) => ({
            questions: questionnaire
        })
    );
// .on(
//     getDraftforGuestUserFx.doneData,
//     (
//         _,
//         {
//             draft: {
//                 templateConfig: { questionnaire }
//             }
//         }
//     ) => ({
//         questions: questionnaire
//     })
// );

sample({
    clock: [
        questionnaireEvents.addQuestionnaireValues,
        changeShowBlockEvent,
        changeShowClauseEvent,
        designEvents.changeActiveDesign,
        designEvents.changePalletIndex,
        designEvents.changeDocumentFonts
    ],
    fn: () => {
        setTimeout(() => {
            setIsLoadingTemplatePagesEvent(false);
            setMountingEvent(false);
        }, 1000);
    }
});
