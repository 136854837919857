import type { FC } from 'types/react';

import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { KiaraLogo } from 'ui-kit/components/links/KiaraLogo';
import { isEllipsisActive } from 'utils/is-ellipsis-active';
import { logoutFx, $user } from 'stores/user/model';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { useMemo, useRef } from 'react';

import {
    UserNameContainer,
    UserNameLastName,
    StyledNavLink,
    LogoContainer,
    LogoutButton,
    StyledAside,
    AvatarInfo,
    LogoutText,
    UserInfo,
    Content,
    Avatar
} from './styles';
import logoutIconSource from './static_assets/logout_icon.svg';
import { getActiveClassName, createLinks } from './utils';
import { PersonIcon } from './static_assets';

export const Aside: FC = () => {
    const { t } = useTranslation();
    const user = useUnit($user);
    const links = useMemo(() => createLinks(t), [t]);
    const userNameRef = useRef(null);

    const { firstName, lastName } = user || {};
    const firstNameFirstLetter = firstName?.slice(0, 1) || '';
    const lastNameFirstLetter = lastName?.slice(0, 1) || '';
    const hasUserInfo = firstNameFirstLetter || lastNameFirstLetter;

    const handleLogout = () => {
        logoutFx();
    };

    return (
        <StyledAside>
            <LogoContainer>
                <KiaraLogo />
            </LogoContainer>

            <Content>
                <Column>
                    {links.map(({ disabled, href, text, icon }, index) => (
                        <StyledNavLink
                            className={getActiveClassName}
                            key={`link-${index}`}
                            disabled={disabled}
                            to={href}
                        >
                            {icon}
                            {text}
                        </StyledNavLink>
                    ))}
                </Column>
            </Content>

            <UserInfo>
                {hasUserInfo ? (
                    <Avatar>
                        <AvatarInfo>{`${firstNameFirstLetter}${lastNameFirstLetter}`}</AvatarInfo>
                    </Avatar>
                ) : (
                    <PersonIcon />
                )}

                <Tooltip
                    isActivated={userNameRef.current !== null && isEllipsisActive(userNameRef.current)}
                    content={`${firstName} ${lastName}`}
                >
                    <UserNameContainer>
                        <UserNameLastName ref={userNameRef}>
                            {firstName} {lastName}
                        </UserNameLastName>
                    </UserNameContainer>
                </Tooltip>
            </UserInfo>
            <ContentWrapper padding="12px 24px">
                <LogoutButton onClick={handleLogout}>
                    <MarginWrapper marginRight="10px">
                        <CustomImage src={logoutIconSource} height="20px" width="20px" />
                    </MarginWrapper>
                    <LogoutText>{t('logOut')}</LogoutText>
                </LogoutButton>
            </ContentWrapper>
        </StyledAside>
    );
};
