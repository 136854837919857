import { lazy } from 'react';

// * private
export const LazyLoadedDocuments = lazy(() => import('../pages/Dashboard/Documents'));
export const LazyLoadedDrafts = lazy(() => import('../pages/Dashboard/Drafts'));
export const LazyLoadedTemplates = lazy(() => import('../pages/Dashboard/Templates'));
export const LazyLoadedSettings = lazy(() => import('../pages/Dashboard/Settings/Settings'));
export const LazyLoadedBuilder = lazy(() => import('../pages/Builder'));
export const LazyLoadedMagic = lazy(() => import('../pages/Magic'));

// * auth
export const LazyLoadedRegister = lazy(() => import('../pages/auth/Register'));
export const LazyLoadedLogin = lazy(() => import('../pages/auth/Login'));
export const LazyLoadedForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
export const LazyLoadedCheckEmail = lazy(() => import('../pages/auth/CheckEmail'));
export const LazyLoadedResetPassword = lazy(() => import('../pages/auth/ResetPassword'));

// * public
export const LazyLoadedMain = lazy(() => import('../pages/Main'));
export const LazyLoadedSocialMedia = lazy(() => import('../pages/auth/SocialMedia'));
export const LazyLoadedPrices = lazy(() => import('../pages/Prices'));
export const LazyLoadedPrivacyPolicy = lazy(() => import('../pages/legal/PrivacyPolicy'));
export const LazyLoadedTermsOfUse = lazy(() => import('../pages/legal/TermsOfUse'));
export const LazyLoadedConstructor = lazy(() => import('../pages/Constructor'));
export const LazyLoadedContactUs = lazy(() => import('../pages/ContactUs'));
// export const LazyLoadedGuestUserPreview = lazy(
//     () => import('../pages/Constructor/guest-user-preview/guest-user-preview')
// );
export const LazyLoadedUserPreview = lazy(
    () => import('../pages/Constructor/guest-user-preview/user-preview/user-preview')
);

// * thank you
export const LazyLoadedThankYouContact = lazy(() => import('../pages/thankYou/Contact'));
export const LazyLoadedThankYouRegistration = lazy(() => import('../pages/thankYou/Registration'));
export const LazyLoadedThankYouVerifyAccount = lazy(() => import('../pages/thankYou/VerifyAccount'));
export const LazyLoadedThankYouPasswordReset = lazy(() => import('../pages/thankYou/PasswordReset'));
export const LazyLoadedThankYouPasswordChanged = lazy(() => import('../pages/thankYou/PasswordChanged'));

// * error 404
export const LazyLoadedError404 = lazy(() => import('../pages/404'));

export const LazyLoadedJoinToCompany = lazy(() => import('../pages/JoinToCompany'));
