import { RadioButton, ListBullets, Calendar, Article, Checks } from 'phosphor-react';
import { FieldTypesEnum } from 'constants/builder/enums';

export const inputTypesForClause = [
    { value: FieldTypesEnum.Radio, label: FieldTypesEnum.Radio, icon: <RadioButton /> },
    { value: FieldTypesEnum.Select, label: FieldTypesEnum.Select, icon: <ListBullets /> }
];

export const icons = {
    MultiSelect: <Checks />,
    Select: <ListBullets />,
    Radio: <RadioButton />,
    Date: <Calendar />,
    Text: <Article />
};
