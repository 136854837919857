import type { TextProperties, Opacity, Color } from 'types/styles';

import { APP_FONT_FAMILY } from 'ui-kit/GlobalStyle/appFontFamily';
import { inheritTextStyleMixin } from 'constants/styles/mixins';
import { PURPLE_60, DANGER } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

export interface SpanProps extends Color, TextProperties, Opacity {}

export interface InputTitleProps extends Color {
    $isError?: boolean;
    $display?: string;
}

export const Span = styled.span<SpanProps>`
    font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : APP_FONT_FAMILY)};
    font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : 'normal')};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : 'unset')};
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 'unset')};
    color: ${({ color }) => (color ? color : 'black')};
    ${({ noWrap }) => (noWrap ? 'white-space: nowrap' : '')};
    ${({ opacity }) => (opacity ? `opacity: ${opacity};` : ``)};
    ${({ alignCenter }) => (alignCenter ? 'text-align: center' : '')}
`;

export const EmphasizedSpan = styled.span<Color>`
    ${inheritTextStyleMixin};
    color: ${({ color }) => color || PURPLE_60};
`;

export const InputTitle = styled.span<InputTitleProps>`
    display: ${({ $display }) => $display || 'inline-flex'};
    min-width: 5px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    ${({ color }) => color && `color: ${color}`};
    margin-bottom: 10px;
    ${({ $isError: isError }) =>
        isError &&
        css`
            color: ${DANGER};
        `}

    @media (max-height: 840px) {
        margin-bottom: 8px;
        font-size: 16px;
        min-height: unset;
    }
`;
