import {
    THANK_YOU_REGISTRATION_LINK,
    THANK_YOU_PASSWORD_RESET,
    DOCUMENTS_LINK,
    AUTH_LINK,
    HOME_LINK
} from 'constants/routes';
import { USER_LOCAL_STORAGE_NAME } from 'constants/local-storage';
import { companyUserChangeModalStore } from 'stores/modals/model';
import { companyEffects, companyStores } from 'stores/company';
import { browserHistory } from 'browserHistory';
import { message } from 'stores/alerts/types';
import { roleStores } from 'stores/role';
import { sample } from 'effector';

import {
    checkEmailForExistenceFx,
    updateUserPasswordFx,
    recoverPasswordFx,
    changePasswordFx,
    changeUserRoleFx,
    verifyAccountFx,
    refreshTokenFx,
    createUserFx,
    loadTokenFx,
    logoutFx,
    getMeFx
} from './model';
import { removeCookieSecureData } from './helpers/remove-cookie-secure-data';
import { setCookieSecureData } from './helpers/set-cookie-secure-data';

const { company } = companyStores;
const { getCompanyUsers } = companyEffects;
const { searchRoleId } = roleStores;

updateUserPasswordFx.doneData.watch(() => {
    logoutFx();
    browserHistory.push(AUTH_LINK);
});

createUserFx.doneData.watch(() => {
    browserHistory.push(THANK_YOU_REGISTRATION_LINK);
});

loadTokenFx.doneData.watch(({ refreshToken, accessToken }) => {
    setCookieSecureData(accessToken, refreshToken);

    browserHistory.push(DOCUMENTS_LINK);
    getMeFx(null);
});

refreshTokenFx.doneData.watch(({ refreshToken, accessToken }) => {
    setCookieSecureData(accessToken, refreshToken);
    getMeFx(null);
});

logoutFx.doneData.watch(() => {
    removeCookieSecureData();
    localStorage.removeItem(USER_LOCAL_STORAGE_NAME);
    window.location.reload();
});

checkEmailForExistenceFx.fail.watch(() => {
    message.error('Email already exists');
});

changeUserRoleFx.doneData.watch(() => {
    companyUserChangeModalStore.closeModal();
});

recoverPasswordFx.doneData.watch(() => {
    browserHistory.push(THANK_YOU_PASSWORD_RESET);
});

changePasswordFx.doneData.watch(() => {
    browserHistory.push(AUTH_LINK);
});

verifyAccountFx.doneData.watch(() => {
    browserHistory.push(DOCUMENTS_LINK);
});

verifyAccountFx.failData.watch(() => {
    browserHistory.push(HOME_LINK);
});

sample({
    fn: ({ searchRoleId, company }) => ({
        companyId: company?.id,
        roleId: searchRoleId,
        limit: 5,
        page: 1
    }),
    source: { searchRoleId, company },
    clock: changeUserRoleFx.doneData,
    target: getCompanyUsers
});
