import type { FC } from 'types/react';

import { downgradeConfirmationStore } from 'stores/modals/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { Modal } from 'ui-kit/components/Modal';
//import { useTranslation } from 'react-i18next';
//import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';

import { ButtonsContainer, BodyContainer, Title, Text } from './styles';

export const DowngradeConfirmationModal: FC = () => {
    const { closeModal, modal } = downgradeConfirmationStore;
    const [_, { handleDowngrade, title, text }] = useUnit(modal);
    //const { t } = useTranslation();
    //const navigate = useNavigate();

    const downgradeSubscription = () => {
        handleDowngrade();
        closeModal();
        // Navigation logic to be added here that will redirect this to some other address.
    };

    return (
        <Modal visible>
            <BodyContainer>
                <Title>{title}</Title>
                <Text>{text}</Text>
            </BodyContainer>
            <ButtonsContainer>
                <Button
                    onClick={() => {
                        closeModal();
                    }}
                    variant="secondary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        downgradeSubscription();
                        closeModal();
                    }}
                    variant="primary"
                >
                    Downgrade
                </Button>
            </ButtonsContainer>
        </Modal>
    );
};
