export enum RolesEnum {
    employees = 'employees',
    admin = 'company-admin',
    owner = 'company-owner'
}

export enum SocialMediaEnum {
    Facebook = 'facebook',
    LinkedIn = 'linkedIn',
    Google = 'google'
}

export enum CopyTemplateEnum {
    Kiara = 1,
    Own = 2
}

export enum Plan {
    Professional = 'professional',
    Enterprise = 'enterprise',
    Basic = 'basic'
}
