import type { BrowserHistory } from 'history';
import type { FC } from 'types/react';

import { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';

interface Props {
    history: BrowserHistory;
    basename?: string;
}

export const BrowserRouter: FC<Props> = ({ basename, children, history }) => {
    const [state, setState] = useState({
        location: history.location,
        action: history.action
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router navigationType={state.action} location={state.location} basename={basename} navigator={history}>
            {children}
        </Router>
    );
};
