import { isEllipsisActive } from 'utils/is-ellipsis-active';
import { Input } from 'ui-kit/components/inputs/Input';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { type FC } from 'types/react';
import { useRef } from 'react';

interface Props {
    text?: string;
}

export const Answer: FC<Props> = ({ text = '' }) => {
    const ref = useRef<HTMLInputElement>(null);

    return (
        <Tooltip isActivated={ref.current !== null && isEllipsisActive(ref.current)} content={text}>
            <Input defaultValue={text} reference={ref} isEllipsis disabled />
        </Tooltip>
    );
};
