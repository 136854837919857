import type { EditorState } from 'draft-js';
import type { FC } from 'types/react';

import { isStyleApplied } from '../../helpers/is-style-applied';
import { BoldIcon } from './static_assets';
import { Button } from './styles';

interface Props {
    editorState: EditorState;
    onClick: () => void;
}

export const BoldButton: FC<Props> = ({ editorState, onClick }) => (
    <Button $isActive={isStyleApplied('BOLD', editorState)} onClick={onClick}>
        <BoldIcon />
    </Button>
);
