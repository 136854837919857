import type { FC } from 'types/react';

import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { Outlet } from 'react-router-dom';

import chatIconSource from './static_assets/chat_icon.svg';
import { Content } from './styles';
import { Header } from './Header';

export const Dashboard: FC = () => (
    <DashboardLayout>
        <Header />
        <Content>
            <Outlet />
        </Content>
        <AbsoluteWrapper bottom="24px" right="24px">
            <CustomImage src={chatIconSource} pointer />
        </AbsoluteWrapper>
    </DashboardLayout>
);
