import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { FormInput } from 'ui-kit/components/inputs/FormInput';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { comingSoonModalStore } from 'stores/modals/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { useFormSchema } from 'hooks/useFormSchema';
import { GRAY_80 } from 'ui-kit/GlobalStyle/colors';
import { P } from 'ui-kit/components/typography/P';
import { Modal } from 'ui-kit/components/Modal';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { type FormEvent } from 'react';
import { type FC } from 'types/react';

import { yupSchema } from './constants';

export const ComingSoonModal: FC = () => {
    const { closeModal, modal } = comingSoonModalStore;
    const [_, { contractName }] = useUnit(modal);
    const { t } = useTranslation();

    const {
        formState: { isValid },
        control
    } = useFormSchema(yupSchema);

    const sendEmail = async (event: FormEvent) => {
        event.preventDefault();
    };

    return (
        <Modal title={t('comingSoonModal.title') as string} onClose={closeModal} width="500px" visible>
            <P>
                {t('comingSoonModal.text1')} {contractName.replaceAll('&shy;', '')} {t('comingSoonModal.text2')}
            </P>
            <form onSubmit={sendEmail}>
                <Row marginTop="20px" justifyBetween alignEnd>
                    <ContentWrapper width="70%">
                        <FormInput
                            title={t('comingSoonModal.placeholder') as string}
                            titleColor={GRAY_80}
                            control={control}
                            name="email"
                        />
                    </ContentWrapper>
                    <Button disabled={!isValid} variant="primary" type="submit">
                        {t('comingSoonModal.buttonText')}
                    </Button>
                </Row>
            </form>
        </Modal>
    );
};
