import { FieldTypesEnum } from 'constants/builder/enums';

import type { Data } from '../types';

export const prepareFieldForUpdate = (data: Data, clauseId: string, contentId: string) => {
    if (data.multiSelectItems?.length) {
        return {
            type: FieldTypesEnum.MultiSelect,
            description: data.description,
            placeholder: data.placeholder,
            entityProps: data.entityProps,
            value: data.multiSelectItems,
            question: data.question,
            markup: 'Text',
            id: data.id,
            contentId,
            clauseId
        };
    }

    if (data.singleSelectItems?.length) {
        return {
            value: data.singleSelectItems,
            description: data.description,
            placeholder: data.placeholder,
            entityProps: data.entityProps,
            type: FieldTypesEnum.Select,
            question: data.question,
            markup: 'Text',
            id: data.id,
            contentId,
            clauseId
        };
    }

    if (data.singleRadioItems?.length) {
        return {
            description: data.description,
            placeholder: data.placeholder,
            entityProps: data.entityProps,
            value: data.singleRadioItems,
            markup: FieldTypesEnum.Text,
            type: FieldTypesEnum.Radio,
            question: data.question,
            id: data.id,
            contentId,
            clauseId
        };
    }

    if (data.type === FieldTypesEnum.Date || data.type === FieldTypesEnum.Text) {
        return {
            description: data.description,
            placeholder: data.placeholder,
            entityProps: data.entityProps,
            question: data.question,
            value: data.value,
            type: data.type,
            id: data.id,
            contentId,
            clauseId
        };
    }

    return data;
};
