import { updateOwnTemplateFx, $ownTemplate } from 'stores/ownTemplates/model';
import { updateSubscriptionModalStore, templateModalStore } from 'stores/modals/model';
import { updateDraftFx, $draft } from 'stores/drafts/model';
import { Column, Row } from 'components/wrappers/grid/FlexWrapper';
import { type ChangeEvent, useEffect, useState } from 'react';
import { getUserLimitationsInfoFx } from 'stores/user/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { Input } from 'ui-kit/components/inputs/Input';
import { Modal } from 'ui-kit/components/Modal';
import { useQuery } from 'hooks/useQuery';
import { useUnit } from 'effector-react';
import { type FC } from 'types/react';

interface TemplateData {
    templateCategory: string;
    templateName: string;
    templateType: string;
    documentName: string;
    templateConfig: any;
    id?: string; // Optional id property
}

export const TemplateModal: FC = () => {
    const { closeModal, modal } = templateModalStore;
    const [_, { documentName, config }] = useUnit(modal);
    const ownTemplate = useUnit($ownTemplate);
    const draft = useUnit($draft);
    const [templateName, setTemplateName] = useState('');
    const [draftName, setDraftName] = useState('');
    // const [documentsName, setDocumentsName] = useState('');
    const location = useQuery<{ ownTemplate?: string; document?: string; draft?: string }>();
    const isUpdatingTemplate = !!location?.ownTemplate || !!ownTemplate;
    const isUpdatingDraft = !!location?.draft || !!draft;
    // const isUpdatingDocument = !!location?.document || !!document;

    const createConfig = async () => {
        let data: TemplateData = {
            templateName: isUpdatingTemplate ? templateName : isUpdatingDraft ? draftName : '',
            templateCategory: 'Hiring',
            templateType: 'Contract',
            templateConfig: config,
            documentName
        };
        if (location?.ownTemplate) {
            await updateOwnTemplateFx({ id: location.ownTemplate, data });
            closeModal();
            return;
        }

        if (isUpdatingDraft) {
            data.id = location?.draft;
            const updaetd_draft = await updateDraftFx(data);
            closeModal();
            return updaetd_draft;
        }

        const userLimitationInfo = await getUserLimitationsInfoFx(null);

        if (userLimitationInfo.userTemplatesCount === userLimitationInfo.subscription.maxTemplatesInYear) {
            closeModal();
            return updateSubscriptionModalStore.openModal({ subtitleText: 'Your templates limitation is exceeded!' });
        }

        // if (location?.ownTemplate) {
        //     await createOwnTemplateFx(data);
        //     closeModal();
        //     return;
        // }

        // if (location?.draft) {
        //     await createDraftFx(data);
        //     closeModal();
        //     return;
        // }

        // if (location?.document) {
        //     await createDraftFx(data);
        //     closeModal();
        //     return;
        // }
    };

    useEffect(() => {
        if (ownTemplate) {
            setTemplateName(ownTemplate?.templateName);
            return () => {
                setTemplateName('');
            };
        }

        if (draft) {
            setDraftName(draft?.draft?.templateName);
            return () => {
                setDraftName('');
            };
        }
    }, [ownTemplate, draft]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (isUpdatingTemplate) {
            setTemplateName(event.target.value);
        } else if (isUpdatingDraft) {
            setDraftName(event.target.value);
        } else {
            setTemplateName(event.target.value);
        }
    };

    return (
        <Modal
            title={isUpdatingTemplate ? 'Update template' : isUpdatingDraft ? 'Update draft' : 'Save Template'}
            onClose={closeModal}
            width="530px"
            visible
        >
            <Column gap="24px">
                <Input
                    placeholder={
                        isUpdatingTemplate
                            ? 'Enter template name here...'
                            : isUpdatingDraft
                              ? 'Enter draft name here...'
                              : 'Enter template name here...'
                    }
                    title={isUpdatingTemplate ? 'Template name' : isUpdatingDraft ? 'Draft Name' : 'Template Name'}
                    value={isUpdatingTemplate ? templateName : isUpdatingDraft ? draftName : templateName}
                    onChange={onChange}
                    disabled={false}
                />
                <Row width="100%" justifyEnd gap="16px">
                    <Button onClick={() => closeModal()} variant="secondary">
                        Cancel
                    </Button>
                    <Button disabled={!templateName && !draftName} onClick={createConfig} variant="primary">
                        {isUpdatingTemplate ? 'Update template' : isUpdatingDraft ? 'Update draft' : 'Save as template'}
                    </Button>
                </Row>
            </Column>
        </Modal>
    );
};
