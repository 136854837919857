import { createNotifyingSuccessEffect, createNotifyingErrorEffect } from 'utils/store';
import { createEffect, createEvent, createStore, restore } from 'effector';
import { USER_LOCAL_STORAGE_NAME } from 'constants/local-storage';
import { persist } from 'effector-storage/local';
// import socket from 'api/socket';
import { API } from 'api';

import { removeSecureDataFromUser } from './helpers/remove-secure-data-from-user';
import { useNavigate } from 'react-router-dom';
// import useSocket from 'api/socket';

// const socket = useSocket();
// * events
export const setUserEvent = createEvent<KIARA_API.AuthResponseDto>();

// * effects
export const loadTokenFx = createNotifyingErrorEffect({
    handler: async (data: API.LoginDto) => {
        const response = await API.user.authenticateUser(data);
        const token = response.accessToken;
        if (token) {
            try {
                localStorage.setItem('token', token);
                localStorage.getItem('token');
            } catch (e) {
                console.error('Error storing token:', e);
            }
        } else {
            console.error('Token is null or undefined');
        }
        return response;
    }
});

export const verifyAccountFx = createNotifyingErrorEffect({
    handler: async (token: string) => await API.user.verifyAccount(token)
});

export const createUserFx = createNotifyingErrorEffect({
    handler: async (data: API.SignUpDto) => await API.user.createUser(data)
});

export const checkEmailForExistenceFx = createNotifyingErrorEffect({
    handler: async (data: { email: string }) => await API.user.checkEmailForExistence(data)
});

export const recoverPasswordFx = createNotifyingErrorEffect({
    handler: async (data: API.ForgotPasswordDto) => await API.user.recoverPassword(data)
});

export const updateUserFx = createNotifyingErrorEffect({
    handler: async (data: API.UpdateUserDto) => await API.user.updateUser(data)
});

export const updateUserPasswordFx = createNotifyingSuccessEffect({
    handler: async (data: API.UpdatePasswordDto) => await API.user.updateUserPassword(data)
});

export const updateEmailFx = createNotifyingErrorEffect({
    handler: async (data: API.UpdateEmailDto) => await API.user.updateEmail(data)
});

export const getMeFx = createNotifyingErrorEffect({
    handler: async () => await API.user.getMe()
});

export const changeUserRoleFx = createNotifyingErrorEffect({
    handler: async (data: { userId: string; role: string }) => await API.user.changeUserRole(data)
});

export const changePasswordFx = createNotifyingErrorEffect({
    handler: async (data: { password: string; token: string }) => await API.user.changePassword(data)
});

export const refreshTokenFx = createNotifyingErrorEffect({
    handler: async () => await API.user.refreshToken()
});

export const logoutFx = createEffect({
    handler: async () => {
        try {
            await API.user.logout();
            console.log('Logged out');
            localStorage.removeItem('token');
        } catch (error: unknown) {
            const e = error as { response?: { status?: number }; message?: string };
            if (e instanceof Error) {
                console.error('Network or other error:', e.message);
            } else if (e.response?.status === 401 || e.message === 'Unauthorized') {
                localStorage.removeItem('token');
                const navigate = useNavigate();
                navigate('/');
                console.log('Session expired or unauthorized, logged out');
            } else {
                console.error('Logout failed:', error);
            }
        }
    }
});

export const getUserLimitationsInfoFx = createNotifyingErrorEffect({
    handler: async () => await API.user.getUserLimitationInfo()
});

// * stores
export const $user = createStore<KIARA_API.User | null>(null)
    .on(loadTokenFx.doneData, (_, payload) => removeSecureDataFromUser(payload))
    .on(createUserFx.doneData, (_, payload) => payload)
    .on(updateUserFx.doneData, (_, payload) => payload)
    .on(refreshTokenFx.doneData, (_, payload) => removeSecureDataFromUser(payload))
    .on(getMeFx.doneData, (_, payload) => payload)
    .on(setUserEvent, (_, payload) => removeSecureDataFromUser(payload));

persist({ key: USER_LOCAL_STORAGE_NAME, store: $user });

export const $doesEmailExist = createStore(false)
    .on(checkEmailForExistenceFx.fail, (_, _data) => false)
    .on(checkEmailForExistenceFx.done, (_, _data) => true);

export const $userLimitationInfo = restore<KIARA_API.UserLimitationInfo>(getUserLimitationsInfoFx.doneData, null);
