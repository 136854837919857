import { getAccessToken } from './get-access-token';

export const getIsAuthorized = () => {
    const accessToken = getAccessToken();

    if (accessToken === undefined) {
        return false;
    }

    return true;
};
