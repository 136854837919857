import axios from 'api/axios';

export const copyDraft = async (data: KIARA_API.CopyDraftRequest) => {
    const { templateName, draftId } = data;

    const newData = {
        templateName,
        draftId
    };
    return axios(
        {
            url: `/user-drafts/copy/${draftId}`,
            method: 'PATCH',
            data: newData
        },
        true
    );
};
