import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import { GRAY_80 } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const Text = styled.p`
    font-size: 16px;
    line-height: 17px;
    color: ${GRAY_80};
    margin-bottom: 28px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 200px;
`;

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.h3`
    ${disableDefaultHStyleMixin};
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;
