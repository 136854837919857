import type { SelectStyles } from 'ui-kit/components/Select/helpers/get-custom-styles';

import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';

import dutchFlagCircularSrc from './static_assets/netherlands-flag-circular.svg';
import englishFlagCircularSrc from './static_assets/usa-flag-circular.svg';

export const headerButtonWidth = '120px';
export const headerPaddingTop = '22px';
export const headerWidth = '420px';
export const headerHeight = '80px';

export const languages = [
    {
        icon: <CustomImage src={englishFlagCircularSrc} height="20px" width="20px" />,
        label: 'English',
        value: 'en'
    },
    {
        icon: <CustomImage src={dutchFlagCircularSrc} height="20px" width="20px" />,
        label: 'Nederlands',
        value: 'du'
    }
];

export const languageSelectStyles: SelectStyles = {
    control: base => ({
        ...base,
        background: 'none',
        border: 'none',
        width: '80px'
    }),
    menu: base => ({
        ...base,
        width: 'max-content'
    })
};
