import { createEvent, createStore, restore } from 'effector';

import { type SetAlertParams } from './types';

// * events
export const setAlertEvent = createEvent<SetAlertParams>();
export const clearAlertEvent = createEvent<number>();
export const incrementEvent = createEvent();

// * stores
export const $idStore = createStore(0).on(incrementEvent, state => state + 1);

export const $alertStore = restore<SetAlertParams>(setAlertEvent, {
    id: $idStore.getState()
}).on(clearAlertEvent, (state, id) => (id !== state.id ? state : { ...state, active: false }));
