import { previewModeModalStore } from 'stores/modals/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { CONSTRUCTOR_LINK } from 'constants/routes';
import { Modal } from 'ui-kit/components/Modal';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { type FC } from 'types/react';

import { BodyContainer, Title, Text } from './styles';
import { WarningIcon } from './static_assets';

export const PreviewModeModal: FC = () => {
    const navigate = useNavigate();
    const location = useQuery<{ ownTemplate?: string }>();
    const draftLocation = useQuery<{ draft?: string }>();
    const documentLocation = useQuery<{ document?: string }>();
    const { closeModal } = previewModeModalStore;

    const handleGoToConstructor = () => {
        closeModal();

        if (location?.ownTemplate) {
            return navigate(`${CONSTRUCTOR_LINK}?ownTemplate=${location.ownTemplate}&previewMode=true`);
        }
        if (draftLocation?.draft) {
            return navigate(`${CONSTRUCTOR_LINK}?draft=${draftLocation.draft}&previewMode=true`);
        }
        if (documentLocation?.document) {
            return navigate(`${CONSTRUCTOR_LINK}?document=${documentLocation.document}&previewMode=true`);
        }

        return navigate(`${CONSTRUCTOR_LINK}?previewMode=true`);
    };

    return (
        <Modal onClose={closeModal} width="346px" visible>
            <BodyContainer>
                <WarningIcon />
                <div>
                    <Title>You’re entering the preview mode</Title>
                    <Text>
                        In the preview mode you can test the questionnaire to see how it works. Please note that the
                        data entered in preview mode is not stored.
                    </Text>
                </div>
                <Button onClick={handleGoToConstructor} variant="primary">
                    OK, I understand
                </Button>
            </BodyContainer>
        </Modal>
    );
};
