import type jsPDF from 'jspdf';

import { createEffect, createEvent, createStore, restore } from 'effector';
import { convertHTMLtoPdf } from 'utils/convert-HTML-to-pdf';
import { createNotifyingErrorEffect } from 'utils/store';
import { message } from 'stores/alerts/types';
import { API } from 'api';

import type { ConvertToPdfAndCreateContractParams } from './types';
import { createContractFromPdf } from './createContractFromPdf ';

export const makePayment = async (data: any) => {
    window.location.href = data._links.checkout.href;
};

// * events
const savePdf = createEvent<{ nameOfPdf: string; pdf: jsPDF }>();
const resetPdf = createEvent();
const resetFileId = createEvent();
const togglePdfGenerationProcessVisible = createEvent();

// * effects
export const uploadContractAsPdf = createNotifyingErrorEffect({
    handler: async (formData: FormData) => await API.contracts.createContract(formData)
});

const convertToPdfAndCreateContract = createEffect({
    handler: async ({ nameOfPdf, pagesIds }: ConvertToPdfAndCreateContractParams) => {
        const result = await createContractFromPdf({ nameOfPdf, pagesIds });

        if (!result) {
            return null;
        }

        const { pdf, id } = result;

        savePdf({ nameOfPdf, pdf });

        return id;
    }
});

// * stores
const pdfData = restore(savePdf, null).reset(resetPdf);
const fileId = restore<string | null>(convertToPdfAndCreateContract.doneData, null).reset(resetFileId);
const isPdfGenerationProcessVisible = createStore(false).on(togglePdfGenerationProcessVisible, state => !state);

// * exports
export const pdfEvents = {
    togglePdfGenerationProcessVisible,
    resetFileId,
    resetPdf,
    savePdf
};

export const pdfEffects = {
    convertToPdfAndCreateContract,
    uploadContractAsPdf
};

export const pdfStores = {
    isPdfGenerationProcessVisible,
    pdfData,
    fileId
};
