import { SelectionState, EditorState, Modifier } from 'draft-js';

import type { EntityProps } from '../Field/Field';

export const updateButtonEntity = (editorState: EditorState, buttonText: string, entityProps: EntityProps) => {
    const contentState = editorState.getCurrentContent();
    const blockKey = entityProps.blockKey;
    const startOffset = entityProps.start;
    const endOffset = entityProps.end;

    const contentStateWithEntity = contentState.createEntity('BUTTON', 'IMMUTABLE');
    const entityKey = entityProps.entityKey;

    const selection = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: startOffset,
        focusOffset: endOffset
    });

    const contentStateWithButton = Modifier.applyEntity(contentStateWithEntity, selection, entityKey);

    const contentStateWithButtonText = Modifier.replaceText(
        contentStateWithButton,
        selection,
        buttonText,
        undefined,
        entityKey
    );

    return EditorState.push(editorState, contentStateWithButtonText, 'insert-characters');
};
