import type { FC } from 'types/react';

import { getIsAuthorized } from 'utils/get-is-authorized';
import { HOME_LINK } from 'constants/routes';
import { Navigate } from 'react-router-dom';

export const PublicRoute: FC = ({ children }) => {
    const isAuthorized = getIsAuthorized();

    if (!isAuthorized) {
        return <>{children}</>;
    }

    return <Navigate to={HOME_LINK} />;
};
