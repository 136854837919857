import { FieldTypesEnum } from 'constants/builder/enums';

export const getFieldType = (selectedValue: FieldTypesEnum) => {
    switch (selectedValue) {
        case FieldTypesEnum.Select:
            return FieldTypesEnum.Select;

        case FieldTypesEnum.Radio:
            return FieldTypesEnum.Radio;
        case FieldTypesEnum.MultiSelect:
            return FieldTypesEnum.MultiSelect;
        default:
            return FieldTypesEnum.Radio;
    }
};
