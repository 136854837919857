import axios from 'api/axios';

export const createTemplate = async (data: KIARA_API.TemplateRequest) =>
    axios<KIARA_API.SuccessAnswer>(
        {
            url: 'me/template',
            data
        },
        true
    );

export const getTemplates = async () =>
    axios<KIARA_API.TemplateResponse[]>({ url: 'me/template', method: 'GET' }, true);

export const getTemplate = async (id: string) =>
    axios<KIARA_API.TemplateResponse>({ url: `me/template/${id}`, method: 'GET' }, true);

export const updateTemplate = async (id: string, data: KIARA_API.TemplateRequest) =>
    axios<KIARA_API.SuccessAnswer>({ url: `me/template/${id}`, method: 'PATCH', data }, true);

export const deleteTemplate = async (id: string) =>
    axios<KIARA_API.SuccessAnswer>({ url: `me/template/${id}`, method: 'DELETE' }, true);
