import { createNotifyingErrorEffect } from 'utils/store';
import { browserHistory } from 'browserHistory';
import { API } from 'api';

interface CopyDraftFxParams extends KIARA_API.CopyDraftRequest {
    templateName: string;
    draftId: string;
}

// * effects
export const copyDraftFx = createNotifyingErrorEffect({
    handler: async ({ templateName, draftId }: CopyDraftFxParams) => {
        const newData: KIARA_API.CopyDraftRequest = {
            templateName,
            draftId
        };

        const response = await API.copyDrafts.copyDraft(newData);

        browserHistory.push('/dashboard/drafts');

        return response;
    }
});
