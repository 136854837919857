import { PURPLE_40, PURPLE_60, PURPLE_80, GRAY_60, WHITE, BLUE } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

import type { ButtonVariant } from './index';

const disabledStyles = css`
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
`;

const primaryStyles = css`
    background-color: ${PURPLE_60};
    color: ${WHITE};

    &:hover {
        background-color: ${PURPLE_80};
    }

    &:disabled {
        ${disabledStyles}
        color: ${GRAY_60};
    }
`;

const secondaryStyles = css`
    background-color: ${WHITE};
    border: 1px solid ${PURPLE_60};
    color: ${PURPLE_60};

    &:hover {
        background-color: ${PURPLE_40};
    }

    &:disabled {
        ${disabledStyles}
        background-color: ${WHITE};
    }
`;

const linkStyles = css`
    background-color: ${WHITE};
    color: ${BLUE};

    &:hover {
        background-color: ${WHITE};
    }

    &:disabled {
        ${disabledStyles}
        color: ${WHITE};
    }
`;

export const StyledButton = styled.button<{ variant: ButtonVariant }>`
    cursor: pointer;
    border: none;
    border-radius: 8px;
    padding: 12px 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;

    ${({ variant }) => variant === 'primary' && primaryStyles}
    ${({ variant }) => variant === 'secondary' && secondaryStyles}
`;

export const StyledAddEmailButton = styled.button<{ variant: ButtonVariant }>`
    cursor: pointer;
    border: none;
    font-family:Anuphan,
    border-radius: 8px;
    padding:5px 15px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    color: #0014CB;

    ${({ variant }) => variant === 'primary' && primaryStyles}
    ${({ variant }) => variant === 'secondary' && secondaryStyles}
     ${({ variant }) => variant === 'light' && linkStyles}
`;

export const StyledMessageButton = styled.button<{ variant: ButtonVariant }>`
    cursor: pointer;
    border: none;
    font-family:Anuphan,
    border-radius: 8px;
    padding:5px 15px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    color: #0014CB;
    display: flex;
    align-items: right;
    position: relative;
    float: right;
    margin-top: 3px;
    margin-bottom: 152px ! important;
    top: -5px;

    ${({ variant }) => variant === 'primary' && primaryStyles}
    ${({ variant }) => variant === 'secondary' && secondaryStyles}
     ${({ variant }) => variant === 'light' && linkStyles}

       &:hover {
        background-color: blue; /* Background color on hover */
        color: white; /* Text color on hover */
    }
`;
export const MessageButtonContainer = styled.div`
    display: flex; /* Enables flexbox layout */
    justify-content: flex-end; /* Aligns children to the right */
`;

export const StyledShowCommentButton = styled.button<{ variant: ButtonVariant }>`
    cursor: pointer;
    border: none;
    display: flex;
    font-family:Anuphan,
    border-radius: 8px;
    padding:5px 15px;
    font-weight: 500;
    width: 160px;
    height: 40px;
    border: 2px solid #0014CB;
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    border-radius: 8px;
    background-color: white !important;
    color: #0014CB !important;

    ${({ variant }) => variant === 'primary' && primaryStyles}
    ${({ variant }) => variant === 'secondary' && secondaryStyles}
     ${({ variant }) => variant === 'light' && linkStyles}

       &:hover {
        background-color: #0014CB !important; /* Background color on hover */
        color: white !important; /* Text color on hover */
    }
`;

export const StyleChangeDocumentButton = styled.button<{ variant: ButtonVariant }>`
    cursor: pointer;
    border: none;
    font-family:Anuphan,
    border-radius: 8px;
    padding:5px 15px;
    font-weight: 500;
    width: 160px;
    height: 40px;
    border: 2px solid #0014CB;
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    border-radius: 8px;
    position: relative;
    float: right;
    top: -30px;
    margin-right: 50px !important;

    ${({ variant }) => variant === 'primary' && primaryStyles}
    ${({ variant }) => variant === 'secondary' && secondaryStyles}
     ${({ variant }) => variant === 'light' && linkStyles}

       &:hover {
        background-color: white !important; /* Background color on hover */
        color: #0014CB !important; /* Text color on hover */
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end; /* Aligns items to the right */
    margin-left: 23px;
`;
