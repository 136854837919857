import type { Data } from 'stores/builder/field/types';

import { setCurrentFieldsEvent } from 'stores/builder/field/model';
import { FieldTypesEnum } from 'constants/builder/enums';

export const createTextField = (
    selectedField: undefined | Data,
    currentFields: Data[],
    placeholder: string,
    description: string,
    question: string,
    contentId: string,
    existingFieldId?: string
) => {
    setCurrentFieldsEvent(
        selectedField
            ? {
                  currentFields: currentFields.map(item =>
                      item.id === selectedField.id
                          ? {
                                type: FieldTypesEnum.Text,
                                placeholder,
                                description,
                                value: '',
                                contentId,
                                question,
                                id: ''
                            }
                          : item
                  ),
                  contentId
              }
            : {
                  currentFields: [
                      ...currentFields,
                      {
                          type: FieldTypesEnum.Text,
                          id: existingFieldId || '',
                          placeholder,
                          description,
                          value: '',
                          contentId,
                          question
                      }
                  ],
                  contentId
              }
    );
};
