import { createEvent, createStore } from 'effector';
import { EditorState } from 'draft-js';

import { decorator } from '../RichTextEditor/constants';

export const setEditorStateEvent = createEvent<{ editorState: EditorState; contentId: string }>();
export const resetEditorStateEvent = createEvent();

export const $editorState = createStore<{ [key: string]: EditorState }>({ '': EditorState.createEmpty() })
    .on(setEditorStateEvent, (state, { editorState, contentId }) => ({
        ...state,
        [contentId]: EditorState.set(editorState, {
            decorator
        })
    }))
    .reset(resetEditorStateEvent);
