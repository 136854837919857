import type { ContentBlock, ContentState } from 'draft-js';

export const findButtonEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();

        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'BUTTON';
    }, callback);
};
