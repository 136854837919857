import type { FC } from 'types/react';

import { combinedModalsStore } from 'stores/modals/model';
import { useUnit } from 'effector-react';

import {
    UpgradeProfessionalConfirmationModal,
    DowngradeConfirmationModal,
    CompanyTemplateNamingModal,
    UpdateSubscriptionModal,
    VariationsBlockModal,
    VariationsFieldModal,
    CreateReminderModal,
    NotAuthorizedModal,
    EmailSuccessModal,
    CopyTemplateModal,
    CopyDraftModal,
    LeaveBuilderModal,
    NotificationModal,
    ColorPalletModal,
    DescriptionModal,
    PreviewModeModal,
    AreYouSureModal,
    ComingSoonModal,
    LeaveDraftModal,
    TemplateModal,
    // DocumentModal,
    AddUserModal,
    // DraftModal,
    CookiesModal,
    ShareModal,
    FieldModal,
    FontsModal
    // ReplyModal // Reply modal import
} from './index';
import { DocumentDownlaodModal } from './DownloadDocumentModal';

export const Modal: FC = () => {
    const {
        isUpgradeProfessionalConfirmationModalVisible,
        isDowngradeConfirmationModalVisible,
        isCompanyTemplateNamingModalVisible,
        isUpdateSubscriptionModalVisible,
        isVariationsFieldModalVisible,
        isVariationsBlockModalVisible,
        isCreateReminderModalVisible,
        isNotAuthorizedModalVisible,
        // isLeaveDocumentModalVisible,
        isEmailSuccessModalVisible,
        isCopyTemplateModalVisible,
        isLeaveBuilderModalVisible,
        isColorPalletModalVisible,
        isDescriptionModalVisible,
        isPreviewModeModalVisible,
        isDownloadDocumentVisible,
        isAreYouSureModalVisible,
        isComingSoonModalVisible,
        isLeaveDraftModalVisible,
        isCopyDraftModalVisible,
        isTemplateModalVisible,
        isAddUserModalVisible,
        isCookiesModalVisible,
        isAlertsModalVisible,
        isFieldModalVisible,
        isFontsModalVisible,
        isShareModalVisible
        // isReplyModalVisible
    } = useUnit(combinedModalsStore);

    return (
        <>
            {isPreviewModeModalVisible && <PreviewModeModal />}
            {isUpgradeProfessionalConfirmationModalVisible && <UpgradeProfessionalConfirmationModal />}
            {isDowngradeConfirmationModalVisible && <DowngradeConfirmationModal />}
            {isEmailSuccessModalVisible && <EmailSuccessModal />}
            {isAlertsModalVisible && <NotificationModal />}
            {isNotAuthorizedModalVisible && <NotAuthorizedModal />}
            {isUpdateSubscriptionModalVisible && <UpdateSubscriptionModal />}
            {isCookiesModalVisible && <CookiesModal />}
            {isAreYouSureModalVisible && <AreYouSureModal />}
            {isTemplateModalVisible && <TemplateModal />}
            {isShareModalVisible && <ShareModal />}
            {isDownloadDocumentVisible && <DocumentDownlaodModal />}
            {/* {isReplyModalVisible && <ReplyModal />} */}
            {/* {isLeaveDraftModalVisible && <DraftModal/>} */}
            {/* {isLeaveDocumentModalVisible && <DocumentMdal>} */}
            {isLeaveBuilderModalVisible && <LeaveBuilderModal />}
            {isFontsModalVisible && <FontsModal />}
            {isColorPalletModalVisible && <ColorPalletModal />}
            {isComingSoonModalVisible && <ComingSoonModal />}
            {isLeaveDraftModalVisible && <LeaveDraftModal />}
            {isCompanyTemplateNamingModalVisible && <CompanyTemplateNamingModal />}
            {isDescriptionModalVisible && <DescriptionModal />}
            {isCreateReminderModalVisible && <CreateReminderModal />}
            {isAddUserModalVisible && <AddUserModal />}
            {isCopyTemplateModalVisible && <CopyTemplateModal />}
            {isCopyDraftModalVisible && <CopyDraftModal />}
            {isFieldModalVisible && <FieldModal />}
            {isVariationsFieldModalVisible && <VariationsFieldModal />}
            {isVariationsBlockModalVisible && <VariationsBlockModal />}
        </>
    );
};
