import { EditorState, Modifier } from 'draft-js';

export const addButtonEntityFromSelection = (editorState: EditorState, buttonText: string) => {
    const contentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const contentStateWithEntity = contentState.createEntity('BUTTON', 'IMMUTABLE');
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentStateWithButton = Modifier.replaceText(
        contentState,
        currentSelection,
        buttonText,
        undefined,
        entityKey
    );

    const newEditorState = EditorState.push(editorState, contentStateWithButton, 'apply-entity');

    return EditorState.forceSelection(newEditorState, contentStateWithButton.getSelectionAfter());
};
