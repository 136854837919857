// * auth
export const AUTH_LINK = '/auth';
export const REGISTER_LINK = '/register';
export const FORGOT_PASSWORD_LINK = '/auth/forgot-password';
export const RESET_PASSWORD_LINK = '/auth/reset-password';
export const JOIN_TO_COMPANY_LINK = '/join-to-company';
export const CHECK_EMAIL_LINK = '/auth/forgot-password/check-email';

// * social media auth
export const SOCIAL_MEDIA_LINK = '/social-media/:provider/redirect';

// * thank you
export const THANK_YOU_VERIFY_ACCOUNT_LINK = '/verify-account/thank-you';
export const THANK_YOU_REGISTRATION_LINK = '/register/thank-you';
export const THANK_YOU_CONTACT_LINK = '/contact/thank-you';
export const THANK_YOU_PASSWORD_CHANGED = '/password-changed/thank-you';
export const THANK_YOU_PASSWORD_RESET = '/password-reset/thank-you';

// * public
export const HOME_LINK = '/';
export const PRICES_LINK = '/prices';
export const PRIVACY_POLICY_LINK = '/privacy-policy';
export const TERM_OF_USE_LINK = '/terms-of-use';
export const GUESTUSER_PREVIEW_LINK = '/guest-user-preview'; // Define the route path for guest preview
export const MAGIC_LINK = '/magic';
export const CONTACT_US_LINK = '/contact-us';

// * private
export const DASHBOARD_LINK = '/dashboard';
export const DASHBOARD_TAB_LINK = '/dashboard/:tabLink';
export const ACCOUNT_LINK = '/dashboard/profile';
export const BUILDER_LINK = '/builder';
export const CONSTRUCTOR_LINK = '/constructor';
export const USER_PREVIEW = '/constructor/user-preview';
export const PREVIEW_BUILDER_LINK = '/preview-builder'; // Define the route path for PreviewBuilder

// * dashboard
export const DOCUMENTS_LINK = '/dashboard/documents';
export const DRAFTS_LINK = '/dashboard/drafts';
export const COMPANY_TEMPLATES_LINK = '/dashboard/company-templates';
export const SETTINGS_LINK = '/dashboard/settings';
export const TEMPLATES_LINK = '/dashboard/templates';
export const KIARA_TEMPLATES_TAB_LINK = '/dashboard/templates?tab=Kiara templates';

// * error 404
export const ERROR_404_LINK = '/404';
