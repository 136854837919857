import { type SelectValue, Select } from 'ui-kit/components/Select/Select';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { isEllipsisActive } from 'utils/is-ellipsis-active';
import { Input } from 'ui-kit/components/inputs/Input';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { type SingleValue } from 'react-select';
import { useEffect, useRef } from 'react';
import { useUnit } from 'effector-react';
import { type FC } from 'types/react';

import { setQuestionEvent, $question } from '../../model';
import { selectOptions, icons } from './constants';
import { Container, Label, Title } from './styles';

interface Props {
    handleSelectChange: (value: string) => void;
    isDisabledEditInput: boolean;
    variationTitle: string;
    selectedValue: string;
}

export const Edit: FC<Props> = ({ isDisabledEditInput, handleSelectChange, variationTitle, selectedValue }) => {
    const ref = useRef<HTMLInputElement>(null);
    const question = useUnit($question);

    useEffect(() => {
        setQuestionEvent(variationTitle || '');
    }, [variationTitle]);

    return (
        <Container>
            <Label htmlFor="value">
                <Tooltip isActivated={ref.current !== null && isEllipsisActive(ref.current)} content={question}>
                    <Input
                        onChange={e => {
                            setQuestionEvent(e.target.value);
                        }}
                        placeholder="Enter your question here..."
                        disabled={!!isDisabledEditInput}
                        defaultValue={question}
                        title="Question"
                        reference={ref}
                        name="value"
                        type="text"
                        isEllipsis
                    />
                </Tooltip>
            </Label>
            <Column width="202px" alignStart>
                <Title>Type</Title>
                <Select
                    defaultValue={{
                        icon: icons[selectedValue as keyof typeof icons],
                        label: selectedValue,
                        value: selectedValue
                    }}
                    onChange={value => {
                        const newValue = value as SingleValue<SelectValue>;

                        handleSelectChange(newValue?.label || '');
                    }}
                    disabled={!!isDisabledEditInput}
                    options={selectOptions}
                    isSearchable={false}
                />
            </Column>
        </Container>
    );
};
