import { PreloaderContainer, PreloaderCircle } from './styles';

interface Props {
    fullScreen?: boolean;
    isLoading: boolean;
}

export function Preloader({ fullScreen = true, isLoading }: Props) {
    if (!isLoading) return null;

    return fullScreen ? (
        <PreloaderContainer>
            <PreloaderCircle fullScreen={fullScreen} />
        </PreloaderContainer>
    ) : (
        <PreloaderCircle />
    );
}
