import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import { copyTemplateModalStore } from 'stores/modals/model';
import { copyTemplateFx } from 'stores/copyTemplates/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { Input } from 'ui-kit/components/inputs/Input';
import { Modal } from 'ui-kit/components/Modal';
import { useUnit } from 'effector-react';
import { type FC } from 'types/react';

import { Column, Row } from './styles';

export const CopyTemplateModal: FC = () => {
    const { closeModal, modal } = copyTemplateModalStore;
    const [_, { templateId, from }] = useUnit(modal);
    const [templateName, setTemplateName] = useState('');

    const onCloseModal = useCallback(() => {
        closeModal();
        setTemplateName('');
    }, [closeModal]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTemplateName(event.target.value);
    };

    const onCreateACopy = () => {
        copyTemplateFx({
            location: 'own',
            templateName,
            templateId,
            from
        });
    };

    useEffect(
        () => () => {
            setTemplateName('');
        },
        []
    );

    return (
        <Modal onClose={onCloseModal} title="Create a copy" width="530px" visible>
            <Column>
                <Input
                    placeholder="Enter template name here..."
                    title="Template name"
                    value={templateName}
                    onChange={onChange}
                />
                <Row>
                    <Button onClick={() => onCloseModal()} variant="secondary">
                        Cancel
                    </Button>
                    <Button disabled={!templateName} onClick={onCreateACopy} variant="primary">
                        Create
                    </Button>
                </Row>
            </Column>
        </Modal>
    );
};
