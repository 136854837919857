import type { FC } from 'types/react';

import { type EditorState, RichUtils } from 'draft-js';

import { StrikethroughButton } from './buttons/StrikethroughButton';
import { SuperscriptButton } from './buttons/SuperscriptButton';
import { MonospaceButton } from './buttons/MonospaceButton';
import { SubscriptButton } from './buttons/SubscriptButton';
import { UnderlineButton } from './buttons/UnderlineButton';
import { ItalicButton } from './buttons/ItalicButton';
import { FieldButton } from './buttons/FieldButton';
import { BoldButton } from './buttons/BoldButton';
import { Container, Content } from './styles';

interface Props {
    onEditorStateChange: (editorState: EditorState) => void;
    onFieldButtonClick?: () => void;
    editorState: EditorState;
    canAddField: boolean;
    disabled?: boolean;
    isFocused: boolean;
}

export const Toolbar: FC<Props> = ({
    onEditorStateChange,
    onFieldButtonClick,
    editorState,
    canAddField,
    isFocused,
    disabled
}) => {
    const onBold = () => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
    };
    const onItalic = () => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
    };
    const onUnderline = () => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
    };
    const onStrikeThrow = () => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH'));
    };
    const onMonospace = () => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'CODE'));
    };
    const onSuperscript = () => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'SUPERSCRIPT'));
    };
    const onSubscript = () => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, 'SUBSCRIPT'));
    };

    return (
        <Container $disabled={disabled}>
            <Content>
                <BoldButton editorState={editorState} onClick={onBold} />
                <ItalicButton editorState={editorState} onClick={onItalic} />
                <UnderlineButton editorState={editorState} onClick={onUnderline} />
                <StrikethroughButton editorState={editorState} onClick={onStrikeThrow} />
                <MonospaceButton editorState={editorState} onClick={onMonospace} />
                <SuperscriptButton editorState={editorState} onClick={onSuperscript} />
                <SubscriptButton editorState={editorState} onClick={onSubscript} />
            </Content>
            {canAddField && <FieldButton onClick={onFieldButtonClick} disabled={!isFocused} />}
        </Container>
    );
};
