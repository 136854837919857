import type { EditorState } from 'draft-js';
import type { FC } from 'types/react';

import { isStyleApplied } from '../../helpers/is-style-applied';
import { SuperscriptIcon } from './static_assets';
import { Button } from './styles';

interface Props {
    editorState: EditorState;
    onClick: () => void;
}

export const SuperscriptButton: FC<Props> = ({ editorState, onClick }) => (
    <Button $isActive={isStyleApplied('SUPERSCRIPT', editorState)} onClick={onClick}>
        <SuperscriptIcon />
    </Button>
);
