import type { Data } from 'stores/builder/field/types';
import type { FC } from 'types/react';

import { $currentFields } from 'stores/builder/field/model';
import { fieldModalStore } from 'stores/modals/model';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { type ContentState } from 'draft-js';
import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import { Button } from './styles';

export interface EntityProps {
    contentState?: ContentState;
    decoratedText?: string;
    offsetKey?: string;
    entityKey: string;
    blockKey: string;
    start: number;
    end: number;
    dir?: null;
}

export const Field: FC<EntityProps> = ({ decoratedText, entityKey, children }) => {
    const commonCurrentFields = useUnit($currentFields);
    const selectedField = useMemo(() => {
        let result: undefined | Data;

        Object.keys(commonCurrentFields).forEach(item => {
            const resultToFind = commonCurrentFields[item].find(item => item.id === entityKey);

            if (resultToFind !== undefined) {
                result = resultToFind;
            }
        });

        return result;
    }, [commonCurrentFields, entityKey]);

    const handleClick = () => {
        if (selectedField !== undefined) {
            fieldModalStore.openModal({
                contentId: selectedField.contentId,
                placeholder: decoratedText,
                selectedField
            });
        }
    };

    return (
        <Button contentEditable={false} onClick={handleClick}>
            <Tooltip content="Edit field" isActivated>
                {children}
            </Tooltip>
        </Button>
    );
};
