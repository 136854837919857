import { type FocusEventHandler, type ChangeEvent, type RefObject, useCallback } from 'react';
import { type FieldValues, useController, type Control } from 'react-hook-form';
import { type FC } from 'types/react';

import { type InputProps, Input } from '../Input';

export interface FormInputProps {
    onBlur?: FocusEventHandler<HTMLInputElement>;
    control: Control<FieldValues>;
    type?: InputProps['type'];
    autoComplete?: string;
    defaultValue?: string;
    description?: string;
    placeholder?: string;
    titleColor?: string;
    disabled?: boolean;
    title?: string;
    name: string;
}

export const FormInput: FC<FormInputProps> = ({
    defaultValue,
    autoComplete,
    placeholder,
    titleColor,
    disabled,
    control,
    onBlur,
    name,
    ...rest
}) => {
    const {
        field: { name: cName, value = '', onChange, ref },
        fieldState: { isTouched, error }
    } = useController({
        defaultValue,
        control,
        name
    });

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onChange(e);
        },
        [onChange]
    );

    return (
        <Input
            refik={ref as unknown as RefObject<any>}
            autoComplete={autoComplete}
            placeholder={placeholder}
            titleColor={titleColor}
            onChange={handleChange}
            error={error?.message}
            isTouched={isTouched}
            disabled={disabled}
            onBlur={onBlur}
            value={value}
            name={cName}
            isFormInput
            {...rest}
        />
    );
};
