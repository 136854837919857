import { createNotifyingErrorEffect } from 'utils/store';
import { CopyTemplateEnum } from 'constants/enums';
import { browserHistory } from 'browserHistory';
import { API } from 'api';

interface CopyTemplateFxParams extends KIARA_API.CopyTemplateRequest {
    from: CopyTemplateEnum;
}

// * effects
export const copyTemplateFx = createNotifyingErrorEffect({
    handler: async ({ templateName, templateId, location, from }: CopyTemplateFxParams) => {
        const newData: KIARA_API.CopyTemplateRequest = {
            templateName,
            templateId,
            location
        };

        const response = await API.copyTemplates.copyTemplate(newData);

        switch (from) {
            case CopyTemplateEnum.Kiara:
                browserHistory.push('/dashboard/templates?tab=My templates');
                break;
        }

        return response;
    }
});
