import { PURPLE_40, PURPLE_60, GRAY_60, DANGER, BLUE, GRAY_100 } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

export const Content = styled.div`
    background-color: ${PURPLE_40};
    padding: 24px;
    max-height: calc(100% - 77px);
    overflow-y: auto;
    padding-bottom: 100px;
`;

const iconContainerMixin = css`
    background-color: ${BLUE};
    border: none;
    color: ${GRAY_100};
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 24px;
    height: 24px;
    border-radius: 4px;

    :hover {
        background-color: ${BLUE};
    }
`;

export const IconContainer = styled.button`
    ${iconContainerMixin}
    :hover {
        color: ${PURPLE_60};
    }
`;

export const GoToQuestionnaireIconContainer = styled.button`
    background-color: inherit;
    padding: 0;
    border: none;
    color: ${GRAY_60};
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    :hover {
        color: ${PURPLE_60};
    }
`;

export const IconsContainer = styled.div`
    display: inline-flex;
    gap: 16px;

    :hover {
        background-color: ${GRAY_60};
    }
`;

export const TrashIconContainer = styled.button`
    ${iconContainerMixin}
    :hover {
        color: ${DANGER};
        background-color: ${GRAY_60};
    }
`;

export const DocumentNameContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const DocumentName = styled.p`
    cursor: pointer;
    text-decoration: underline;
`;
