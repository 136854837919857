import { convertHTMLtoPdf } from 'utils/convert-HTML-to-pdf';
import { message } from 'stores/alerts/types';
import type { ConvertToPdfAndCreateContractParams } from './types';
import { uploadContractAsPdf } from './model';

export const createContractFromPdf = async ({ nameOfPdf, pagesIds }: ConvertToPdfAndCreateContractParams) => {
    // Convert HTML to PDF
    const fileData = await convertHTMLtoPdf({
        nameOfPdf: `${nameOfPdf}.pdf`,
        targetNodeIds: pagesIds
    });

    if (!fileData) {
        message.error('File was not created');
        return null;
    }

    const { file, pdf } = fileData;
    console.log('file', fileData);
    // Prepare FormData and upload contract
    const formData = new FormData();
    formData.append('file', file);

    // Upload the contract as PDF
    const { id } = await uploadContractAsPdf(formData);

    return { pdf, id };
};
