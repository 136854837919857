import { addEntityPropsIntoFields } from 'components/builder/TextEditor/helpers/add-entity-props-into-fields';
import { $editorState } from 'components/builder/TextEditor/model';
import { DraggableTypesEnum } from 'constants/builder/enums';
import { createEvent, createStore, sample } from 'effector';

import type { Data } from './types';

import { questionnaireStores } from '../questionnaire';

// * events
export const setCurrentFieldsEvent = createEvent<{ currentFields: Data[]; contentId: string }>();
export const resetCurrentFieldsEvent = createEvent();
export const updateFieldEvent = createEvent<{
    contentId: string;
    clauseId: string;
    fieldId: string;
    blockId: string;
    // TODO: any
    newField: any;
}>();

// * stores
export const $currentFields = createStore<{ [key: string]: Data[] }>({ '': [] }).on(
    setCurrentFieldsEvent,
    (state, { currentFields, contentId }) => ({ ...state, [contentId]: currentFields })
);
export const $allFields = questionnaireStores.questionnaireItems.map(items =>
    items.questions.filter(question => question.questionType !== DraggableTypesEnum.Subject)
);

sample({
    fn: ({ previousFields, editorState }, { currentFields, contentId }) => ({
        ...previousFields,
        [contentId]: addEntityPropsIntoFields(editorState[contentId], currentFields)
    }),
    source: { previousFields: $currentFields, editorState: $editorState },
    clock: setCurrentFieldsEvent,
    target: $currentFields
});
