import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import queryString from 'qs';

export const useQuery = <T>() => {
    const location = useLocation();

    const query = useMemo(
        () => queryString.parse(location.search, { ignoreQueryPrefix: true }) as unknown as T,
        [location.search]
    );

    return query;
};
