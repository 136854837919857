import type { Variations } from 'types/builder/interface';

import { v4 as generateId } from 'uuid';

export const getVariationItems = (variationsField?: Variations) => {
    if (variationsField) {
        return variationsField[0][0].value.map(item => ({
            ...item,
            id: generateId()
        }));
    }

    return [
        {
            value: {
                value: '',
                type: ''
            },
            id: generateId(),
            isDefault: true,
            text: ''
        },
        {
            value: {
                value: '',
                type: ''
            },
            id: generateId(),
            isDefault: true,
            text: ''
        }
    ];
};
