import { createEvent } from 'effector';

import type {
    MakeClauseVariantIsDefaultEventParams,
    DeleteClauseVariantEventParams,
    ChangeClauseTitleEventProps,
    AddClauseVariantEventParams,
    DeleteClauseEventProps,
    AddClauseEventProps
} from './types';

// * events
export const addClauseEvent = createEvent<AddClauseEventProps>();
export const deleteClauseEvent = createEvent<DeleteClauseEventProps>();
export const changeClauseTitleEvent = createEvent<ChangeClauseTitleEventProps>();
export const toggleClauseNumberingEvent = createEvent();
export const addClauseVariantEvent = createEvent<AddClauseVariantEventParams>();
export const deleteClauseVariantEvent = createEvent<DeleteClauseVariantEventParams>();
export const makeClauseVariantIsDefaultEvent = createEvent<MakeClauseVariantIsDefaultEventParams>();
