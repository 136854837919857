import type { PreviewThemes } from 'types/preview';

export const previewThemes: PreviewThemes = {
    design_pallets_2: [
        {
            second: '#D9EDFF',
            first: '#00417D',
            third: '#00417D',
            fourth: '#fff'
        },
        {
            second: '#D9EDFF',
            first: '#eff7ff',
            third: '#000000',
            fourth: '#fff'
        },
        {
            second: '#00417D',
            first: '#D9EDFF',
            third: '#EF5818',
            fourth: '#fff'
        },
        {
            isAdded: false,
            second: '',
            fourth: '',
            first: '',
            third: ''
        }
    ],
    design_pallets_4: [
        {
            first: '#002D56',
            third: '#00417D',
            fourth: '#FFF',
            second: ''
        },
        {
            fourth: '#00417D',
            first: '#e9ebff',
            third: '#00417D',
            second: ''
        },
        {
            isAdded: false,
            second: '',
            fourth: '',
            first: '',
            third: ''
        }
    ],
    design_pallets_3: [
        {
            first: '#267667',
            fourth: '#FFF',
            third: '#000',
            second: ''
        },
        {
            fourth: '#267667',
            first: '#f1fffc',
            third: '#000',
            second: ''
        },
        {
            isAdded: false,
            second: '',
            fourth: '',
            first: '',
            third: ''
        }
    ],
    design_pallets_1: [
        {
            first: '#EF5818',
            fourth: '#fff',
            third: '#000',
            second: ''
        },
        {
            first: '#F0F1F8',
            fourth: '#000',
            third: '#000',
            second: ''
        },
        {
            isAdded: false,
            second: '',
            fourth: '',
            first: '',
            third: ''
        }
    ]
};
