import { getMeFx } from 'stores/user/model';

import { createDocumentFx, deleteDocumentFx, getDocumentsFx } from './model';

deleteDocumentFx.doneData.watch(() => {
    getDocumentsFx(null);
});

createDocumentFx.doneData.watch(() => {
    getMeFx(null);
});
