import type { FC } from 'types/react';

import { updateSubscriptionModalStore } from 'stores/modals/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { P } from 'ui-kit/components/typography/P';
import { SETTINGS_LINK } from 'constants/routes';
import { Modal } from 'ui-kit/components/Modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';

import { Footer } from './styles';

export const UpdateSubscriptionModal: FC = () => {
    const { closeModal, modal } = updateSubscriptionModalStore;
    const [_, { subtitleText }] = useUnit(modal);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const updateSubscription = () => {
        closeModal();
        navigate(`${SETTINGS_LINK}?tab=Subscription`);
    };

    return (
        <Modal
            customFooter={
                <Footer>
                    <Button onClick={updateSubscription} variant="primary">
                        {t('updateSubscriptionModal.primaryButton')}
                    </Button>
                </Footer>
            }
            title={t('updateSubscriptionModal.updateSubscription') as string}
            onClose={closeModal}
            width="555px"
            visible
        >
            <P>{subtitleText}</P>
        </Modal>
    );
};
