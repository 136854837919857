import type { FieldTypesEnum } from 'constants/builder/enums';

import { type SetStateAction, type ChangeEvent, type Dispatch, useRef } from 'react';
import { isEllipsisActive } from 'utils/is-ellipsis-active';
import { Input } from 'ui-kit/components/inputs/Input';
import { MinusIconV2, PlusIcon } from 'ui-kit/assets';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { type FC } from 'types/react';

import { Container, Content, Button } from './styles';

export interface FieldItem {
    type: FieldTypesEnum;
    value: string;
    text: string;
    id: string;
}

export interface AddOrRemoveButtonProps {
    type?: 'remove' | 'add';
    itemType?: string;
    index: number;
}

interface Props {
    handleOnChangeAnswers: (props: AddOrRemoveButtonProps) => void;
    setItems: Dispatch<SetStateAction<FieldItem[]>>;
    fieldType: FieldTypesEnum;
    items: FieldItem[];
}

export const SelectMultiSelectRadioEditor: FC<Props> = ({ handleOnChangeAnswers, fieldType, setItems, items }) => {
    const ref = useRef<HTMLInputElement>(null);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        const newState = items.map((item, i) => (index === i ? { ...item, text: value, value } : item));

        setItems(newState);
    };

    return (
        <Container>
            {items.map(({ value, id }, index) => (
                <Content key={id}>
                    <Tooltip isActivated={ref.current !== null && isEllipsisActive(ref.current)} content={value}>
                        <Input
                            placeholder="Enter a possible answer here"
                            onChange={e => handleOnChange(e, index)}
                            defaultValue={value}
                            reference={ref}
                            type="text"
                            isEllipsis
                        />
                    </Tooltip>
                    <Button
                        onClick={() => {
                            handleOnChangeAnswers({
                                itemType: fieldType,
                                type: 'add',
                                index
                            });
                        }}
                        type="button"
                    >
                        <PlusIcon height={12} width={12} />
                    </Button>
                    <Button
                        onClick={() => {
                            handleOnChangeAnswers({
                                itemType: fieldType,
                                type: 'remove',
                                index
                            });
                        }}
                        type="button"
                    >
                        <MinusIconV2 height={2} width={12} />
                    </Button>
                </Content>
            ))}
        </Container>
    );
};
