import { clearAlertEvent, incrementEvent, setAlertEvent, $idStore } from './model';
import { type messageType, type alertType, message } from './types';

const ALERT_LIVE_TIME = 5000;

const messageArray = Object.keys(message) as alertType[];

const setAlertByType = (message: messageType, type: alertType) => {
    incrementEvent();
    const id = $idStore.getState();
    setTimeout(() => clearAlertEvent(id), ALERT_LIVE_TIME);
    setAlertEvent({
        active: true,
        message,
        type,
        id
    });
};

for (let key of messageArray) message[key] = (message: messageType) => setAlertByType(message, key);
