import styled, { css } from 'styled-components';

export const Container = styled.div<{ $disabled?: boolean }>`
    border: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--white);
    border-bottom: 1px solid #d0d3d9;
    padding: 8px 12px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    ${({ $disabled }) =>
        $disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

export const Content = styled.div`
    display: flex;
    gap: 8px;
`;
