import axios from 'api/axios';

export const createSubscription = (data: KIARA_API.MollieSubscriptionRequest) =>
    axios<KIARA_API.MollieSubscription>({
        url: 'mollie/subscription',
        data
    });

export const createExtraDocument = (data: KIARA_API.MollieExtraDocumentRequest) =>
    axios({
        url: 'mollie/extra-document',
        data
    });

export const cancelPayment = () =>
    axios({
        url: 'mollie/cancel'
    });

export const getPaymentStatus = (data: KIARA_API.MolliePaymentStatusRequest) =>
    axios({
        url: `mollie/status/${data.id}`
    });

export const getSubscriptions = () =>
    axios<KIARA_API.MollieSubscriptionResponse[]>({
        url: 'subscription',
        method: 'GET'
    });

export const cancelSubscription = (data: KIARA_API.MolliePaymentStatusRequest) =>
    axios({
        url: 'mollie/cancel',
        data
    });

export const buyExtraDocument = (data: KIARA_API.DocumentRequest) =>
    axios<KIARA_API.ExtraDocumentResponse>({
        url: 'mollie/extra-document',
        data
    });
