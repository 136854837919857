import type { SimpleButtonProps, ButtonProps } from 'components/common/buttons/SimpleButton/types';
import type { FC } from 'types/react';

import { PURPLE_60, ORANGE } from 'ui-kit/GlobalStyle/colors';

import { StyledSimpleButton } from './styles';

export const SimpleButton: FC<SimpleButtonProps> = ({ styleType, disabled, children, ...props }) => (
    <StyledSimpleButton {...props} styleType={styleType} disabled={disabled}>
        {children}
    </StyledSimpleButton>
);

export const SupportButton: FC<ButtonProps> = props => (
    <SimpleButton
        {...props}
        backgroundColor="white"
        styleType="support"
        color={PURPLE_60}
        fontSize="16px"
        height="48px"
    />
);

export const SupportButtonSecondary: FC<ButtonProps> = props => (
    <SimpleButton {...props} backgroundColor="white" styleType="support" fontSize="16px" color={ORANGE} height="48px" />
);
