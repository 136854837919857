import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import { PURPLE_60, PURPLE_80, WHITE } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const Button = styled.button`
    ${disableDefaultButtonStyleMixin}
    cursor: pointer;
    border-radius: 4px;
    padding: 2px;
    font-size: inherit;
    background-color: ${PURPLE_60};
    color: ${WHITE};

    &:hover {
        background-color: ${PURPLE_80};
    }
`;
