import { $draftSignMessage, $draftSignEmails, $draftSignName, $draft } from 'stores/drafts/model';
import { createDocumentFx } from 'stores/documents/model';
import { sample } from 'effector';

import { pdfEffects, pdfEvents, pdfStores } from './model';

pdfEffects.convertToPdfAndCreateContract.failData.watch(() => {
    pdfEvents.togglePdfGenerationProcessVisible();
});

pdfEffects.convertToPdfAndCreateContract.doneData.watch(() => {
    pdfEvents.togglePdfGenerationProcessVisible();
});

sample({
    fn: ({ documentName, pdfData, message, fileId, draft }) => {
        if (pdfData && fileId && draft) {
            const { pdf } = pdfData;

            return {
                name: documentName,
                massage: message,
                description: '',
                draft: draft.id,
                fileId,
                pdf
            };
        }
    },
    source: {
        documentName: $draftSignName,
        pdfData: pdfStores.pdfData,
        message: $draftSignMessage,
        fileId: pdfStores.fileId,
        emails: $draftSignEmails,
        draft: $draft
    },
    clock: pdfEffects.convertToPdfAndCreateContract.doneData,
    target: createDocumentFx
});
