import { getOwnTemplateFx } from 'stores/ownTemplates/model';
import { getDraftFx } from 'stores/drafts/model';

import { $coverStyles } from '.';

$coverStyles
    .on(getOwnTemplateFx.doneData, (_, { templateConfig: { coreConfig } }) => coreConfig)
    .on(
        getDraftFx.doneData,
        (
            _,
            {
                draft: {
                    templateConfig: { coreConfig }
                }
            }
        ) => coreConfig
    );
