import type { Data } from 'stores/builder/field/types';

import { getEntityKeys } from 'components/builder/RichTextEditor/helpers/get-entity-keys';
import { type EditorState, convertToRaw } from 'draft-js';

export const addEntityPropsIntoFields = (editorState: EditorState, currentFields: Data[]): Data[] => {
    const contentState = editorState.getCurrentContent();

    const blocksRaw = convertToRaw(contentState);

    const entityKeys = getEntityKeys(editorState);

    return blocksRaw.blocks
        .map(block =>
            currentFields.map((item, index) => ({
                ...item,
                entityProps: {
                    end: block.entityRanges[index].offset + block.entityRanges[index].length,
                    start: block.entityRanges[index].offset,
                    entityKey: entityKeys[index],
                    blockKey: block.key
                },
                id: entityKeys[index]
            }))
        )
        .flat();
};
