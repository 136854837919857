export const getDefaultShowOrHideString = (value: string) => {
    if (!value) {
        return {
            value: '',
            label: ''
        };
    }

    if (value !== 'show' && value !== 'hide') {
        return { value: value.split('-')[1], label: value.split('-')[1] };
    }

    return { label: value, value };
};
