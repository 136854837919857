import { GRAY_100, GRAY_40, GRAY_80 } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 5fr 2fr;
    grid-column-gap: 10px;
    margin-bottom: 10px;
    align-items: flex-end;

    & > div {
        width: 100%;
        display: flex;
    }
`;

export const Label = styled.label`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Title = styled.span`
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: ${GRAY_100};
    margin-bottom: 4px;
`;

export const SelectWrapper = styled.div`
    width: 202px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const NewFieldText = styled.span`
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    color: ${GRAY_100};

    margin-bottom: 14px;
`;

export const Hr = styled.hr`
    width: 100%;
    height: 1px;
    border: none;
    background-color: ${GRAY_40};
    margin: 24px 0 18px 0;
`;

export const CloseIconWrapper = styled.div<{ $disabled: boolean }>`
    cursor: pointer;
    color: ${GRAY_80};

    ${({ $disabled }) =>
        $disabled &&
        css`
            cursor: default;
            opacity: 0.5;
        `}
`;

export const AllFieldsSelectWrapper = styled.div`
    width: 202px;
    display: flex;
    align-items: center;
    gap: 10px;
`;
