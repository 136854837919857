import type { SingleValue, MultiValue } from 'react-select';
import type { RadioValue } from 'types/builder/interface';
import type { FC } from 'types/react';

import { hideOrShowEvents, hideOrShowStores } from 'stores/builder/hideOrShowItems';
import { type SelectValue, Select } from 'ui-kit/components/Select/Select';
import { Input } from 'ui-kit/components/inputs/Input';
import { useUnit } from 'effector-react';

import { MultiselectContainer, SelectContainer, Container, Content, Title, Item, Hr } from './styles';
import { getDefaultShowOrHideString } from '../../helpers/get-default-show-or-hide-string';
import { selectOptions } from './constants';
import { $question } from '../../model';
import { Answer } from './Answer';

interface Props {
    clauseOptions: Array<{ value: string; label: string }>;
    variationItems: RadioValue[];
    blockId?: string;
}

export const Logic: FC<Props> = ({ variationItems, clauseOptions }) => {
    const { setMultiSelectValues, setShowOrHide } = hideOrShowEvents;
    const showOrHide = useUnit(hideOrShowStores.showOrHide);
    const question = useUnit($question);

    const handleSelectShowOrHide = (value: string, key: string) => {
        setShowOrHide({ value, key });
    };

    const handleSelectIndex = (
        key: string,
        value: MultiValue<{
            value: string;
            label: string;
        }>
    ) => {
        setMultiSelectValues({ value, key });
    };

    return (
        <Container>
            <Hr />
            {variationItems.map((item, index) => {
                const showOrHideDefaultValue = getDefaultShowOrHideString(showOrHide[index]?.value);

                const defaultMultiSelectValues = clauseOptions.filter(option =>
                    showOrHide[index]?.value
                        .split('-')[0]
                        .split(',')
                        .some(index => option.value === String(index))
                );

                return (
                    <Content key={index}>
                        <Item>
                            <Title>
                                <b>IF</b> the answer to:
                            </Title>
                            <Answer text={question} />
                        </Item>
                        <Item>
                            <Title>IS:</Title>
                            <Answer text={item.text} />
                            <Title>THEN:</Title>
                            <SelectContainer>
                                <Select
                                    onChange={value => {
                                        const newValue = value as SingleValue<SelectValue>;
                                        handleSelectShowOrHide(newValue?.label || '', index.toString());
                                    }}
                                    defaultValue={showOrHideDefaultValue}
                                    options={selectOptions}
                                />
                            </SelectContainer>
                            <Input value="section" type="text" disabled />
                            <MultiselectContainer key={defaultMultiSelectValues.toString()}>
                                <Select
                                    onChange={value => {
                                        const newValue = value as MultiValue<SelectValue>;
                                        handleSelectIndex(index.toString(), newValue);
                                    }}
                                    defaultValue={defaultMultiSelectValues}
                                    options={clauseOptions}
                                    isClearable={false}
                                    width="172px"
                                    isMulti
                                />
                            </MultiselectContainer>
                        </Item>
                    </Content>
                );
            })}
        </Container>
    );
};
