import { type SelectValue, Select } from 'ui-kit/components/Select/Select';
import { type SingleValue, type MultiValue } from 'react-select';
import { Button } from 'ui-kit/components/buttons/Button';
import { useCallback, useEffect, useState } from 'react';
import { fontsModalStore } from 'stores/modals/model';
import { designEvents } from 'stores/builder/design';
import { type PreviewFonts } from 'types/preview';
import { Modal } from 'ui-kit/components/Modal';
import { type FC } from 'types/react';

import { SelectContainer, Footer, Label, Span, Hr, Or } from './styles';
import { fontPairingOptions, fontOptions } from './constants';
import { useDefaultFonts } from './hooks/useDefaultFonts';

export const FontsModal: FC = () => {
    const { fontPairingDefaultValue, headingDefaultValue, bodyDefaultValue } = useDefaultFonts();
    const [fonts, setFonts] = useState<PreviewFonts>({
        heading: 'Helvetica',
        body: 'Poppins'
    });

    const updateDocumentFonts = useCallback(
        (
            data: SingleValue<SelectValue> | MultiValue<SelectValue> | SelectValue,
            type: 'heading' | 'body' | 'all'
        ): void => {
            if (data && 'value' in data) {
                const value = data.value;
                if (type === 'all') {
                    setFonts({
                        heading: value.slice(0, value.indexOf('+') - 1),
                        body: value.slice(value.indexOf('+') + 2)
                    });
                } else if (type === 'heading') {
                    setFonts({
                        ...fonts,
                        heading: value
                    });
                } else {
                    setFonts({
                        ...fonts,
                        body: value
                    });
                }
            }
        },
        [fonts]
    );

    const handleCancel = useCallback(() => {
        setFonts({ heading: '', body: '' });
        fontsModalStore.closeModal();
    }, []);

    const handleSave = useCallback(() => {
        const fontsToSave = {
            heading: `${fonts.heading}, sans-serif`,
            body: `${fonts.body}, sans-serif`
        };
        designEvents.changeDocumentFonts(fontsToSave);
        fontsModalStore.closeModal();
    }, [fonts]);

    useEffect(() => {
        if (bodyDefaultValue && headingDefaultValue) {
            setFonts({ heading: headingDefaultValue.value, body: bodyDefaultValue.value });
        }
    }, [bodyDefaultValue, headingDefaultValue]);

    return (
        <Modal
            customFooter={
                <Footer>
                    <Button onClick={handleCancel} variant="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant="secondary">
                        Save
                    </Button>
                </Footer>
            }
            onClose={fontsModalStore.closeModal}
            title="Fonts"
            width="500px"
            visible
        >
            <Label>
                <Span>Choose a font pairing:</Span>
            </Label>
            <SelectContainer>
                <Span>Font pairing</Span>
                <Select
                    onChange={newValue => {
                        updateDocumentFonts(newValue, 'all');
                    }}
                    value={{ label: `${fonts.heading} + ${fonts.body}`, value: `${fonts.heading} + ${fonts.body}` }}
                    defaultValue={fontPairingDefaultValue}
                    options={fontPairingOptions}
                />
            </SelectContainer>
            <Or>
                <Hr />
                <Span>or</Span>
                <Hr />
            </Or>
            <Label>
                <Span>Choose your own fonts:</Span>
            </Label>
            <SelectContainer>
                <Span>Headings</Span>
                <Select
                    onChange={newValue => updateDocumentFonts(newValue, 'heading')}
                    value={{ label: fonts.heading, value: fonts.heading }}
                    defaultValue={headingDefaultValue}
                    options={fontOptions}
                />
            </SelectContainer>
            <SelectContainer>
                <Span>Body Text</Span>
                <Select
                    onChange={newValue => updateDocumentFonts(newValue, 'body')}
                    value={{ label: fonts.body, value: fonts.body }}
                    defaultValue={bodyDefaultValue}
                    options={fontOptions}
                />
            </SelectContainer>
        </Modal>
    );
};
