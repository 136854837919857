import type { SocialMediaEnum } from 'constants/enums';

import { getRefreshToken } from 'utils/get-refresh-token';
import { BASE_URL } from 'constants/services';
import axiosRequest from 'axios';
import axios from 'api/axios';

export const createUser = (data: API.SignUpDto) =>
    axios<KIARA_API.User>(
        {
            url: '/auth/signup',
            data
        },
        false
    );

export const authenticateUser = (data: API.LoginDto) =>
    axios<KIARA_API.AuthResponseDto>(
        {
            url: '/auth/login',
            data
        },
        false
    );

export const checkEmailForExistence = (data: { email: string }) =>
    axios<{ message: string }>({
        url: '/auth/check-email',
        data
    });

export const socialMediaAuthorize = (name: SocialMediaEnum) => window.location.replace(`${BASE_URL}/auth/${name}`);

export const authenticateUserWithSocialMedia = (name: SocialMediaEnum) =>
    axios<KIARA_API.AuthResponseDto>(
        {
            url: `/auth/${name}`,
            method: 'GET'
        },
        false
    );

export const recoverPassword = (data: API.ForgotPasswordDto) =>
    axios<KIARA_API.AuthResponseDto>(
        {
            url: '/auth/forgot-password',
            data
        },
        false
    );

export const updateUser = (data: API.UpdateUserDto) =>
    axios<KIARA_API.User>({
        url: '/users/update/me',
        method: 'PATCH',
        data
    });

export const updateUserPassword = (data: API.UpdatePasswordDto) =>
    axios<API.SuccessAnswer>({
        url: '/users/me/update-password',
        method: 'PATCH',
        data
    });

export const updateEmail = (data: API.UpdateEmailDto) =>
    axios<API.SuccessAnswer>({
        url: '/users/me/update-email',
        method: 'PATCH',
        data
    });

export const getMe = () =>
    axios<KIARA_API.User>({
        url: '/users/me',
        method: 'GET'
    });

export const changeUserRole = (data: { userId: string; role: string }) =>
    axios({
        url: 'users/change-role',
        data
    });

export const verifyAccount = (token: string) =>
    axios<KIARA_API.AuthResponseDto>(
        {
            url: `users/verify/${token}`,
            method: 'GET'
        },
        false
    );

export const changePassword = (data: { password: string; token: string }) =>
    axios<KIARA_API.AuthResponseDto>(
        {
            url: 'auth/change-password',
            data
        },
        false
    );

export const logout = async () =>
    axios(
        {
            url: 'auth/logout'
        },
        true
    );

export const getUserLimitationInfo = () =>
    axios<KIARA_API.UserLimitationInfo>({ url: 'users/limitation-info', method: 'GET' });

export const refreshToken = async (): Promise<KIARA_API.AuthResponseDto> => {
    const response = await axiosRequest.post<KIARA_API.AuthResponseDto>(`${BASE_URL}/auth/refresh`, {
        refreshToken: getRefreshToken()
    });

    return response.data;
};
