import type { QuestionnaireItem } from 'stores/builder/questionnaire/types';
import type { Data } from 'stores/builder/field/types';
import type { EditorState } from 'draft-js';

import { addButtonEntityFromScratch } from 'components/builder/RichTextEditor/helpers/add-button-entity-from-scratch';
import { setEditorStateEvent } from 'components/builder/TextEditor/model';
import { FieldTypesEnum } from 'constants/builder/enums';

import type { FieldItem } from '../SelectMultiSelectRadioEditor';

import { createMultiselectField } from './create-multiselect-field';
import { createSelectField } from './create-select-field';
import { createRadioField } from './create-radio-field';
import { createTextField } from './create-text-field';

export const createFieldFromExistingField = (
    editorState: EditorState,
    existingField: QuestionnaireItem,
    currentFields: Data[],
    contentId: string
) => {
    const { type: selectedValue, title: placeholder, description = '', subtitle = '', value } = existingField;

    setEditorStateEvent({ editorState: addButtonEntityFromScratch(editorState, existingField.title), contentId });

    switch (selectedValue) {
        case FieldTypesEnum.MultiSelect:
            createMultiselectField(
                value as FieldItem[],
                undefined,
                currentFields,
                subtitle,
                description,
                placeholder,
                contentId,
                existingField.id
            );
            break;
        case FieldTypesEnum.Select:
            createSelectField(
                value as FieldItem[],
                undefined,
                currentFields,
                subtitle,
                description,
                placeholder,
                contentId,
                existingField.id
            );
            break;
        case FieldTypesEnum.Radio:
            createRadioField(
                value as FieldItem[],
                undefined,
                currentFields,
                subtitle,
                description,
                placeholder,
                contentId,
                existingField.id
            );
            break;
        default:
            createTextField(undefined, currentFields, placeholder, description, subtitle, contentId, existingField.id);
    }
};
