import type { Placement } from '@popperjs/core';

import { WHITE } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

export const Popper = styled.div<{ placement: Placement }>`
    background-color: ${WHITE};
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #555f61;
    max-width: 200px;
    word-break: break-all;
    padding: 3px 8px;
    z-index: 9999;
    min-width: 100px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.48);

    ${({ placement }) => {
        switch (placement) {
            case 'top':
                return css`
                    margin-bottom: 10px;
                `;
            case 'bottom':
                return css`
                    margin-top: 10px;
                `;
            case 'right':
                return css`
                    margin-left: 10px;
                `;
            case 'left':
                return css`
                    margin-right: 10px;
                `;

            default:
                return '';
        }
    }}
`;

export const Arrow = styled.div<{ placement: Placement }>`
    width: 0;
    height: 0;
    border-style: solid;

    ${({ placement }) => {
        switch (placement) {
            case 'top':
                return css`
                    border-width: 5px 5px 0 5px;
                    border-color: ${WHITE} transparent transparent transparent;
                    bottom: -5px;
                    margin-top: 0;
                    margin-bottom: 0;
                `;
            case 'bottom':
                return css`
                    border-width: 0 5px 5px 5px;
                    border-color: transparent transparent ${WHITE} transparent;
                    top: -5px;
                    margin-top: 0;
                    margin-bottom: 0;
                `;
            case 'right':
                return css`
                    border-width: 5px 5px 5px 0;
                    border-color: transparent ${WHITE} transparent transparent;
                    left: -5px;
                    margin-left: 0;
                    margin-right: 0;
                `;
            case 'left':
                return css`
                    border-width: 5px 0 5px 5px;
                    border-color: transparent transparent transparent ${WHITE};
                    right: -5px;
                    margin-left: 0;
                    margin-right: 0;
                `;
            default:
                return '';
        }
    }}
`;

export const Target = styled.div`
    & > div {
        height: 100%;
    }
`;
