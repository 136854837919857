import { createEffect, createEvent, createStore, restore } from 'effector';
import { createNotifyingErrorEffect } from 'utils/store';
import { API } from 'api';

// * events
export const resetDraftEvent = createEvent();
export const changeDraftNameEvent = createEvent<string>();

// * effects
export const createDraftFx = createEffect({
    handler: async (data: KIARA_API.UserDraftsRequest) => await API.draft.createDraft(data)
});

export const getDraftsFx = createNotifyingErrorEffect({ handler: async () => API.draft.getDrafts() });
//get drat forguest user public api
export const getDraftGuestFx = createNotifyingErrorEffect({
    handler: async (id: string) => API.draft.getDraftGuest(id)
});
export const getDraftforGuestUserFx = createNotifyingErrorEffect({
    handler: async ({ token, email, id }: { token: string; email: string; id: string }) =>
        API.draft.getDraftforGuestUser(token, id, email)
});
export const sendRequestAccessFx = createNotifyingErrorEffect({
    handler: async (params: { formData: FormData; id: string }) =>
        API.draft.sendRequestAccess(params.id, params.formData)
});
export const getDraftFx = createNotifyingErrorEffect({ handler: async (id: string) => API.draft.getDraft(id) });
// export const getDraftforGuestUserFx = createNotifyingErrorEffect({
// handler: async ({ token, id, email }: { token: string; id: string, email:string }) => {
//  const response = await API.draft.getDraftforGuestUser(token,id,email);
// console.log("---repsosenses are hereeee", response)
// //  if (token) {
// //     try {
// //         localStorage.setItem('token', token);
// //         localStorage.getItem('token')
// //         console.log('Token stored successfully:', localStorage.getItem('token'));
// //     } catch (e) {
// //         console.error('Error storing token:', e);
// //     }
// // } else {
// //     console.error('Token is null or undefined');
// // }
//  return response;
// }
// });
export const deleteDraftFx = createNotifyingErrorEffect({
    handler: async (id: string) => await API.draft.deleteDraft(id)
});

export const updateDraftFx = createEffect({
    handler: async ({
        templateCategory,
        templateConfig,
        documentName,
        templateName,
        templateType,
        id
    }: KIARA_API.UserDraftsRequest) =>
        await API.draft.updateDraft(
            { templateCategory, templateConfig, documentName, templateName, templateType },
            id || ''
        )
});

// export const updateDraftFx = createEffect({
//     handler: async ({ data, id }: { data: KIARA_API.UserDraftsRequest; id: string }) =>
//         await API.draft.updateDraft(data, id)
// });

// * stores
// craete this store to share document
export const setShareData = createEvent<{
    documentName: string;
    emails: string[];
    comment: string;
    files: File[];
}>();
type ShareData = {
    documentName: string;
    emails: string[];
    comment: string;
    files: File[];
};
export const shareDataStore = createStore<ShareData>({
    documentName: '',
    comment: '',
    emails: [],
    files: []
}).on(setShareData, (_, payload) => payload);

export const $drafts = createStore<KIARA_API.UserDraftsResponse[]>([]);
export const $draft = createStore<KIARA_API.UserDraftsResponse | null>(null);
export const $draftforGuest = createStore<KIARA_API.UserDraftsResponse | null>(null);
export const $guestDraft = createStore<KIARA_API.UserDraftsResponse | null>(null);
export const $draftSignName = restore<string>(changeDraftNameEvent, '');
export const $draftSignEmails = createStore<string[]>([]);
export const $draftSignMessage = createStore<string>('');

$drafts.on(getDraftsFx.doneData, (_, payload) => payload);
$draft.on(getDraftFx.doneData, (_, payload) => payload);
$draftforGuest.on(getDraftFx.doneData, (_, payload) => payload);
$guestDraft.on(getDraftforGuestUserFx.doneData, (_, payload) => payload);

$draft.reset(resetDraftEvent);
