import { EditorState, Modifier } from 'draft-js';

export const addButtonEntityFromScratch = (editorState: EditorState, buttonText: string) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('BUTTON', 'IMMUTABLE');
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const currentSelection = editorState.getSelection();

    const contentStateWithButton = Modifier.applyEntity(contentStateWithEntity, currentSelection, entityKey);

    const contentStateWithButtonText = Modifier.insertText(
        contentStateWithButton,
        currentSelection,
        buttonText,
        undefined,
        entityKey
    );

    return EditorState.push(editorState, contentStateWithButtonText, 'insert-characters');
};
