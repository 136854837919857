import styled from 'styled-components';

export const Text = styled.p`
    margin-bottom: 20px;
`;

export const RadioContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
