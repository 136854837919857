import type { FC } from 'types/react';

import { changeDraftNameEvent, $draftSignName } from 'stores/drafts/model';
import { AdaptiveGrid } from 'components/wrappers/grid/GridWrapper';
import { Column, Row } from 'components/wrappers/grid/FlexWrapper';

import { InputTitle } from 'ui-kit/components/typography/Span';
import { Input } from 'ui-kit/components/inputs/Input';
import { useTranslation } from 'react-i18next';
import { $user, getUserLimitationsInfoFx } from 'stores/user/model';
import { useUnit } from 'effector-react';
import { useState } from 'react';
import { Modal } from 'ui-kit/components/Modal';
import { downloadDocumnetModalStore, updateSubscriptionModalStore } from 'stores/modals/model';
import { pdfEvents } from 'stores/pdf/model';
import { Button } from 'ui-kit/components/buttons/Button';

export const DocumentDownlaodModal: FC = () => {
    const draftSignName = useUnit($draftSignName);
    const { closeModal, modal } = downloadDocumnetModalStore;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const onDownload = async () => {
        const userLimitationInfo = await getUserLimitationsInfoFx(null);

        if (userLimitationInfo.userDocumentsCount === userLimitationInfo.subscription.maxDocumentInYear) {
            setLoading(false);
            updateSubscriptionModalStore.openModal({ subtitleText: 'Your documents limitation is exceeded!' });
            closeModal();
        } else {
            setLoading(true);
            pdfEvents.togglePdfGenerationProcessVisible();
            closeModal();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //     const initialMessage = useMemo(
    //         () => `${t('constructor.signing.textAreaInitialMessage.firstPart')}
    // ${user?.firstName || 'John'} ${user?.lastName || 'Smith'} ${t('constructor.signing.textAreaInitialMessage.secondPart')}
    // ${user?.firstName || 'John'}`,
    //         [t, user]
    //     );

    return (
        <Modal title={'Download document'} onClose={closeModal} width="530px" visible>
            <Column gap="24px">
                {/* <Text>Are you sure you want to send the document?</Text> */}
                <Row width="100%" justifyEnd gap="16px">
                    <AdaptiveGrid marginBottom="100px" xxl="1fr">
                        <InputTitle>{t('constructor.signing.text1')}</InputTitle>
                        {/* <EffectorFormTextInputGroup />  */}
                        <Input
                            onChange={event => {
                                changeDraftNameEvent(event.target.value);
                            }}
                            title={t('constructor.signing.documentName') as string}
                            defaultValue={draftSignName}
                            placeholder="Document name"
                        />
                        {/* <FormTextArea /> */}
                    </AdaptiveGrid>
                    <Button onClick={() => closeModal()} variant="secondary">
                        No
                    </Button>
                    <Button onClick={onDownload} disabled={loading} variant="primary">
                        {loading ? 'Downloading...' : 'Download'}
                    </Button>
                </Row>
            </Column>
        </Modal>
    );
};
