import { createNotifyingErrorEffect } from 'utils/store';
import { API } from 'api';

// * effects
export const sendQuestionFx = createNotifyingErrorEffect({
    handler: async (data: API.FeedbackDto) => API.contact.sendQuestion(data)
});

export const sendQuestionForSubScriptionFx = createNotifyingErrorEffect({
    handler: async (data: API.FeedbackDto) => API.contact.sendQuestionForSubscription(data)
});
