import type { FC } from 'types/react';

import { areYouSureModalStore } from 'stores/modals/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { Modal } from 'ui-kit/components/Modal';
import { useUnit } from 'effector-react';

import { ButtonsContainer, BodyContainer, Title, Text } from './styles';
import { AreYouSureIcon } from './static_assets';

export const AreYouSureModal: FC = () => {
    const { closeModal, modal } = areYouSureModalStore;
    const [_, { handleSubmit, title, text }] = useUnit(modal);

    return (
        <Modal visible>
            <BodyContainer>
                <AreYouSureIcon />
                <Title>{title}</Title>
                <Text>{text}</Text>
            </BodyContainer>
            <ButtonsContainer>
                <Button
                    onClick={() => {
                        closeModal();
                    }}
                    variant="secondary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleSubmit();
                        closeModal();
                    }}
                    variant="primary"
                >
                    Delete
                </Button>
            </ButtonsContainer>
        </Modal>
    );
};
