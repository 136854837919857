import { THANK_YOU_CONTACT_LINK } from 'constants/routes';
import { browserHistory } from 'browserHistory';

import { sendQuestionForSubScriptionFx, sendQuestionFx } from './model';

sendQuestionFx.doneData.watch(() => {
    browserHistory.push(THANK_YOU_CONTACT_LINK);
});

sendQuestionForSubScriptionFx.doneData.watch(() => {
    browserHistory.push(THANK_YOU_CONTACT_LINK);
});
