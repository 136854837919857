import { type FC } from 'types/react';
import { useState } from 'react';

import { Container, Ball } from './styles';

export interface SwitchProps {
    onChange?: (active: boolean) => void;
    defaultState?: boolean;
    color?: string;
}

export const Switch: FC<SwitchProps> = ({ defaultState = false, onChange, color }) => {
    const [value, setValue] = useState(defaultState);

    const onClick = () => {
        setValue(!value);
        onChange?.(!value);
    };

    return (
        <Container onClick={onClick} $active={value} $color={color}>
            <Ball $active={value} />
        </Container>
    );
};
