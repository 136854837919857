import axios from 'api/axios';

export const sendQuestion = (data: API.FeedbackDto) =>
    axios<API.SuccessAnswer | API.BadRequestDto>({
        url: 'contact-us',
        data
    });

export const sendQuestionForSubscription = (data: API.FeedbackDto) =>
    axios<API.SuccessAnswer | API.BadRequestDto>({
        url: 'contact-us/subscription',
        data
    });
