import { showCookieConsentEvent } from 'stores/cookieConsent/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { cookiesModalStore } from 'stores/modals/model';
import { COOKIE_CONSENT_NAME } from 'constants/cookie';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { CookieConsentWrapper, HighlightedText, TextContainer } from './styles';

export function CookieBanner() {
    const { t } = useTranslation();

    const openCookiesModal = () => {
        cookiesModalStore.openModal();
    };

    const onAccept = () => {
        Cookies.set(COOKIE_CONSENT_NAME, 'true');
        showCookieConsentEvent(false);
    };

    return (
        <CookieConsentWrapper>
            <TextContainer>
                {t('cookieBanner.text')}{' '}
                <HighlightedText onClick={openCookiesModal}>{t('cookieBanner.link')}</HighlightedText>.
            </TextContainer>
            <Button variant="secondary" onClick={onAccept}>
                {t('accept')}
            </Button>
        </CookieConsentWrapper>
    );
}
